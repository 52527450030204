import { useState, ReactNode } from 'react';
import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';
import { DateTime } from 'luxon';
import { Info, Loader } from 'react-feather';

import { formatPrice } from '~/lib/utils';
import { reactivateAccount } from '~/api/account';
import { useAuth } from '~/contexts/Auth';
import { Reactivated } from '~/components/account/AlertMessages';
import Modal from '~/components/shared/Modal';
import Button from '~/components/ui/Button';
import { ErrorData } from '~/types/data';
import { AccountData } from '~/models/Account';
import { AlertClassName } from '~/components/account/Alert';

export interface ReactivateAccountModalProps {
  open: boolean;
  handleClose(): void;
  setAccount(account: AccountData): void;
  price: number;
  rebillingDate: string;
  canceled: boolean;
  showAlert(className: AlertClassName, message: ReactNode): void;
}

function Body({ reactivateMessage }: { reactivateMessage: string }) {
  return (
    <div className="h-72 px-16 text-center mx-auto">
      <Info size="100" className="mx-auto my-8 text-blue-500" />
      <h4 className="text-white mb-6">
        Reactivate My Subscription
      </h4>
      <p className="text-white mb-1">
        {reactivateMessage}
      </p>
    </div>
  );
}

function LoadingBody({ reactivateMessage }: { reactivateMessage: string }) {
  return (
    <div className="h-72 px-16 text-center mx-auto">
      <Loader size="100" className="mx-auto my-8 text-blue-500 animate-spin-slow" />
      <h4 className="text-white mb-6">
        Reactivating Your Subscription
      </h4>
      <p className="text-white mb-1">
        {reactivateMessage}
      </p>
    </div>
  );
}

function ReactivateAccountModal({
  open,
  handleClose,
  price,
  rebillingDate,
  canceled,
  setAccount,
  showAlert,
}: ReactivateAccountModalProps) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const handleReactivate = async () => {
    try {
      setLoading(true);
      const data = await reactivateAccount(auth.user.uuid);
      setAccount(data);
      showAlert('success', Reactivated);
    } catch (err) {
      const error = err as AxiosError<ErrorData>;
      Bugsnag.notify(error, (event) => {
        event.addMetadata('userUuidDetails', { uuid: auth.user.uuid });
      });
      showAlert('warning', (<span>Unable to reactivate subscription.</span>));
    } finally {
      handleClose();
      setTimeout(() => setLoading(false), 250);
    }
  };

  const formattedRebillingDate = DateTime.fromSQL(rebillingDate).toFormat('DDD');
  const formattedPrice = formatPrice(price);
  const reactivateMessage = canceled
    ? `Your credit card will be charged $${formattedPrice} and your subscription will renew automatically at the beginning of the next billing period.`
    : `Your subscription will renew automatically at the beginning of the next billing period, ${formattedRebillingDate}.`;
  const body = loading ? (
    <LoadingBody
      reactivateMessage={reactivateMessage}
    />
  ) : (
    <Body
      reactivateMessage={reactivateMessage}
    />
  );
  const acceptText = loading
    ? 'Done'
    : 'Reactivate Subscription';
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      body={body}
      acceptButton={(
        <Button
          label={acceptText}
          type="button"
          disabled={loading}
          variant="primary"
          color="success"
          size="large"
          onClick={handleReactivate}
        />
      )}
      rejectButton={(
        <Button
          label="Never Mind"
          type="button"
          disabled={loading}
          variant="outlined"
          color="white"
          size="large"
          onClick={handleClose}
        />
      )}
    />
  );
}

export default ReactivateAccountModal;

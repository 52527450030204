import algoliasearch from 'algoliasearch';

import {
  SearchTypesEnum, SearchData, SearchTypeOption, SearchType,
} from '~/api/data';
import { getRequest } from '~/api';
import { API_PATHS, ALGOLIA_APP_ID } from '~/lib/constants';

export enum IndexEnum {
  tags = 'search_tags',
  categories = 'search_categories',
  courses = 'search_courses'
}

const resolveType = (index = ''): SearchTypeOption => {
  let type;
  switch (index) {
    case IndexEnum.tags:
      type = SearchTypesEnum.tag;
      break;
    case IndexEnum.categories:
      type = SearchTypesEnum.category;
      break;
    case IndexEnum.courses:
      type = SearchTypesEnum.course;
      break;
    default:
      throw new Error('Incorrect index provded');
  }
  return type;
};

export const getAlgoliaSearchData = async (key: string, query: string): Promise<SearchType[]> => {
  const client = algoliasearch(ALGOLIA_APP_ID, key);
  const queries = [
    IndexEnum.tags, IndexEnum.categories, IndexEnum.courses,
  ].map(indexName => ({ indexName, query, params: { attributesToRetrieve: ['name', 'slug'] } }));
  const { results } = await client.multipleQueries<SearchData>(queries);
  return results.map(({ index, hits }) => ({ type: resolveType(index), data: hits }));
};

export const getKey = async (): Promise<string> => {
  const { data } = await getRequest(API_PATHS.ALGOLIA_KEY);
  return data.searchKey || '';
};

export default { getAlgoliaSearchData };

import { useCourseContext } from '~/contexts/Course';

function ProgressBar() {
  const { progress } = useCourseContext();

  if (progress === 0) {
    return null;
  }

  return (
    <div className="h-1 bg-gray-700">
      <div className="absolute bg-white/80 h-1" style={{ width: `${progress}%` }} />
    </div>
  );
}

export default ProgressBar;

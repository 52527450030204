import Thumbnail from '~/components/course/Thumbnail';
import VideoPlayer from '~/components/shared/VideoPlayer';
import Name from '~/components/shared/Name';
import Content from '~/components/shared/Content';
import Tags from '~/components/shared/Tags';
import LessonCount from '~/components/course/LessonCount';
import DownloadCount from '~/components/course/DownloadCount';
import StartButton from '~/components/course/StartButton';
import CourseProgress from '~/components/course/CourseProgress';
import ProgressBar from '~/components/course/ProgressBar';
import { FEATURED_MEDIA_VIDEO_TYPE } from '~/components/course/lib/constants';
import { IMG_LOADING_EAGER } from '~/lib/constants';
import { useCourseContext } from '~/contexts/Course';

function CourseHeader() {
  const {
    name,
    tags,
    thumbnail,
    content,
    video,
    featuredMediaType,
  } = useCourseContext();
  return (
    <div className="course-header">
      <div className="page-container">
        <div className="detail-page-layout">
          {featuredMediaType === FEATURED_MEDIA_VIDEO_TYPE
            ? (
              <div className="course-header-thumbnail">
                <VideoPlayer video={video} />
              </div>
            ) : (
              <div className="course-header-thumbnail">
                <Thumbnail
                  image={thumbnail}
                  fallbackAlt={name}
                  imgLoading={IMG_LOADING_EAGER}
                />
              </div>
            )}
          <div className="detail-page-content">
            <Name name={name} />
            <Content content={content} />
            <Tags tags={tags} />
            <div className="pt-4 md:pt-6 md:flex md:justify-between">
              <div className="mb-4 md:mb-0">
                <h5 className="eyebrow mb-4">What&rsquo;s Included</h5>
                <div className="flex items-start">
                  <LessonCount />
                  <DownloadCount />
                </div>
              </div>
              <div className="flex flex-col-reverse items-stretch md:items-center md:flex-row">
                <CourseProgress />
                <StartButton />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProgressBar />
    </div>
  );
}

export default CourseHeader;

import PropTypes from 'prop-types';

export interface NameProps {
  name: string;
}

function Name({ name }: NameProps) {
  return (
    <h5 className="name">{name}</h5>
  );
}

Name.propTypes = { name: PropTypes.string.isRequired };

export default Name;

import {
  createContext,
  useState,
  useMemo,
  useContext,
} from 'react';
import Bugsnag from '@bugsnag/js';

import { User } from '~/models/User';
import { authProvider, logoutUser, UserData } from '~/api/auth';

export interface AuthContextType {
  user: User;
  signin: (user: UserData, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  clearUserState: () => void;
}

const nullUser = new User();

const defaultUser = new User({
  userUuid: localStorage.getItem('uuid') || '',
  mkOwnerUuid: localStorage.getItem('marketplaceOwnerUuid') || '',
  userCreatedAt: { date: localStorage.getItem('createdAt') || '' },
  firstName: localStorage.getItem('firstName') || '',
  lastName: localStorage.getItem('lastName') || '',
  email: localStorage.getItem('email') || '',
  launchDarklyHash: localStorage.getItem('launchDarklyHash') || '',
});

export const AuthContext = createContext<AuthContextType>({
  user: defaultUser,
  signin: () => null,
  signout: () => null,
  clearUserState: () => null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>(defaultUser);

  const signin = (newUser: UserData, callback: VoidFunction) => authProvider.signin(() => {
    const { token } = newUser;
    if (token) {
      localStorage.setItem('token', token);

      const userObj = new User(newUser);
      localStorage.setItem('uuid', userObj.uuid);
      localStorage.setItem('marketplaceOwnerUuid', userObj.marketplaceOwnerUuid);
      localStorage.setItem('createdAt', `${userObj.createdAt}`);
      localStorage.setItem('firstName', userObj.firstName);
      localStorage.setItem('lastName', userObj.lastName);
      localStorage.setItem('email', userObj.email);
      localStorage.setItem('launchDarklyHash', userObj.launchDarklyHash);
      setUser(userObj);
    } else {
      Bugsnag.notify(new Error('Token not found when logging in user'), (event) => {
        event.addMetadata('authSigninUserDetails', {
          id: newUser.id,
          userUuid: newUser.userUuid,
          mkOwnerUuid: newUser.mkOwnerUuid,
          userCreatedAt: newUser.userCreatedAt?.date,
          email: newUser.email,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          token: newUser.token,
        });
      });
    }
    callback();
  });

  const clearUserState = () => {
    localStorage.clear();
    setUser(nullUser);
  };

  const signout = (callback: VoidFunction) => authProvider.signout(() => {
    logoutUser().then(() => {
      clearUserState();
      callback();
    });
  });

  const value = useMemo(
    () => ({
      user,
      signin,
      signout,
      clearUserState,
    }),
    [
      user,
      signin,
      signout,
      clearUserState,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

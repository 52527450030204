import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import { Video } from '~/models/Video';
import LinearLoadBar from '~/components/ui/LinearLoadBar';
import { UPLOADED_VIDEO_URL } from '~/lib/constants';

export interface VideoPlayerProps {
  video?: Video;
  autoplay?: boolean;
}

function VideoPlayer({ video, autoplay }: VideoPlayerProps) {
  const { url } = video || new Video();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!url.includes(UPLOADED_VIDEO_URL));
  }, [url]);

  if (!url.length) {
    return null;
  }

  return (
    <div className="relative flex-grow">
      {loading && (
        <div className="absolute top-0 inset-x-0">
          <LinearLoadBar />
        </div>
      )}
      <div className="video-container">
        <ReactPlayer
          controls
          className="video-player"
          url={url}
          playing={autoplay}
          width="100%"
          height="100%"
          onReady={() => setLoading(false)}
        />
      </div>
    </div>
  );
}

VideoPlayer.propTypes = {
  video: PropTypes.instanceOf(Video),
  autoplay: PropTypes.bool,
};
VideoPlayer.defaultProps = {
  video: undefined,
  autoplay: false,
};

export default VideoPlayer;

import PropTypes from 'prop-types';
import { Download as DownloadIcon } from 'react-feather';

import { Download as DownloadModel } from '~/models/Download';
import Button from '~/components/ui/Button';

export interface DownloadProps {
  download: DownloadModel;
}

function Download({ download }: DownloadProps) {
  const { title, url } = download;

  return (
    <div className="download-item">
      <div className="inline-block">
        <Button
          leadingIcon={<DownloadIcon />}
          label={title.length ? title : 'Download file'}
          onClick={() => window.open(url)}
          variant="iconLink"
        />
      </div>
    </div>
  );
}

Download.propTypes = { download: PropTypes.instanceOf(DownloadModel).isRequired };

export default Download;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import Input from '~/components/ui/Input';
import Button from '~/components/ui/Button';
import ErrorMessage from '~/components/ui/ErrorMessage';
import LinearLoadBar from '~/components/ui/LinearLoadBar';
import LoginHeader from '~/components/auth/LoginHeader';
import LoginHeaderExistingAccount from '~/components/auth/LoginHeaderExistingAccount';
import LoginHeaderCanceledAccount from '~/components/auth/LoginHeaderCanceledAccount';
import LoginHeaderPasswordReset from '~/components/auth/LoginHeaderPasswordReset';
import { useAuth } from '~/contexts/Auth';
import { PATHS, SIGNUP_LINK } from '~/lib/constants';
import { validateLogin, enrollUser } from '~/api/auth';
import useDocumentTitle from '~/hooks/title';

export interface LoginPageParams {
  existing: boolean;
  resetSuccess: boolean;
  canceled: boolean;
}

export interface LocationState {
  from?: {
    pathname: string;
  };
}

function LoginPage({ existing, resetSuccess, canceled }: LoginPageParams) {
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState('');
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const state = location.state as LocationState;
  const from = state?.from?.pathname || '/';

  useDocumentTitle('Login');

  useEffect(() => {
    if (auth.user.signedIn) {
      navigate('/');
    }
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPristine(true);
    setProcessing(true);
    validateLogin(email, password)
      .then(response => auth.signin(response, () => {
        const cart = localStorage.getItem('cart');
        if (cart) {
          enrollUser(cart, response?.firstName || '', response?.lastName || '')
            .then(() => {
              localStorage.removeItem('cart');
              const course = localStorage.getItem('courseRedirect');
              if (course) {
                localStorage.removeItem('courseRedirect');
                navigate(`/course/${course}`);
              } else {
                navigate(from, { replace: true });
              }
            })
            .catch((err) => {
              auth.signout(() => {
                setError(err);
                setProcessing(false);
              });
            });
        } else {
          navigate(from, { replace: true });
        }
      }))
      .catch((err) => {
        setError(err);
        setProcessing(false);
      });
  };

  const handleChange = (func: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPristine(false);
    setError('');
    func(event.currentTarget.value);
  };

  const displayError = pristine && !!error.length;

  return (
    <>
      {resetSuccess && <LoginHeaderPasswordReset />}
      {existing && <LoginHeaderExistingAccount />}
      {canceled && <LoginHeaderCanceledAccount />}
      {!resetSuccess && !existing && !canceled && <LoginHeader />}

      <form onSubmit={handleSubmit} aria-label="Login form">
        <div className="pt-8">
          <Input
            id="email"
            label="Email address"
            name="email"
            type="text"
            disabled={processing}
            value={email}
            onChange={handleChange(setEmail)}
            error={displayError}
          />
        </div>

        <div className="pt-8">
          <Input
            id="password"
            label="Password"
            name="password"
            type="password"
            disabled={processing}
            value={password}
            onChange={handleChange(setPassword)}
            error={displayError}
          />
        </div>

        <div className="pt-8">
          <Button
            type="submit"
            label="Login"
            disabled={pristine || !email.length || !password.length}
          />
          {processing && (
            <div className="-mt-1 rounded-b-lg h-1 overflow-hidden">
              <LinearLoadBar />
            </div>
          )}
        </div>

        <ErrorMessage error={displayError} message={error} />

        <p className="pt-4"><Link to={PATHS.FORGOT}>Forgot Password?</Link></p>
        <p className="pt-4">
          Don&apos;t have an account?
          {' '}
          <a href={SIGNUP_LINK}>Sign up now.</a>
        </p>
      </form>
    </>
  );
}

LoginPage.propTypes = {
  existing: PropTypes.bool,
  resetSuccess: PropTypes.bool,
  canceled: PropTypes.bool,
};

LoginPage.defaultProps = {
  existing: false,
  resetSuccess: false,
  canceled: false,
};

export default LoginPage;

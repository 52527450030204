import PropTypes from 'prop-types';

import { Course } from '~/models/Course';
import Card from '~/components/course/Card';
import { ImgLoadingAttribute } from '~/api/data';
import { CourseListProps } from '~/types/components';

export interface RowProps extends CourseListProps {
  className: string;
  imgLoading?: ImgLoadingAttribute;
}

function Row({ className, courses, imgLoading }: RowProps) {
  if (!courses.length) {
    return null;
  }

  return (
    <div className={className}>
      {courses.map(course => (
        <Card
          key={course.id}
          course={course}
          imgLoading={imgLoading}
        />
      ))}
    </div>
  );
}

Row.propTypes = {
  className: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(PropTypes.instanceOf(Course)).isRequired,
  imgLoading: PropTypes.string.isRequired,
};

export default Row;

import Bugsnag, { NotifiableError } from '@bugsnag/js';

import Account from '~/models/Account';
import User from '~/models/User';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[];
  }
}

export function initializePendo(user: User, account: Account): void {
  try {
    window.dataLayer.push({
      event: 'pendo_initialize',
      pendo_uuid: user.uuid,
      pendo_account_uuid: user.accountUuid,
      pendo_email: user.email,
      pendo_name: `${user.firstName} ${user.lastName}`,
      pendo_created_at: user.createdAt,
      pendo_cu_only: !user.hasMarketplace,
      pendo_cu_tier: account.tier === 'creatorulive' ? 'live' : 'standard',
      pendo_subscription_active: account.isActive,
    });
  } catch (error) {
    Bugsnag.notify(error as NotifiableError);
  }
}

export default initializePendo;

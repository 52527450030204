import LinearLoadBar from '~/components/ui/LinearLoadBar';

function PageLoading() {
  return (
    <div className="pt-14 xl:pt-16">
      <LinearLoadBar />
    </div>
  );
}

export default PageLoading;

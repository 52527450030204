import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '~/contexts/Auth';
import { PATHS } from '~/lib/constants';

function LogoutPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.clearUserState();
    const token = localStorage.getItem('token');
    if (token) {
      auth.signout(() => {
        navigate(PATHS.LOGIN);
      });
    } else {
      navigate(PATHS.LOGIN);
    }
  }, [auth]);

  return null;
}

export default LogoutPage;

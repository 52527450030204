import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

import { BUGSNAG_API_KEY, ENV_NAME } from '~/lib/constants';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: ENV_NAME,
  onError(event) {
    const id = localStorage.getItem('uuid') || undefined;
    const email = localStorage.getItem('email') || undefined;
    const firstName = localStorage.getItem('firstName') || '';
    const lastName = localStorage.getItem('lastName') || '';
    const fullName = `${firstName} ${lastName}`.trim();
    const name = fullName.length ? fullName : undefined;
    event.setUser(id, email, name);
  },
});

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const ErrorBoundary = plugin.createErrorBoundary(React);

export default ErrorBoundary;

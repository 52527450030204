import LessonVideo from '~/components/lesson/LessonVideo';
import LessonDetails from '~/components/lesson/LessonDetails';

function LessonHeader() {
  return (
    <div className="lesson-details">
      <div className="page-container">
        <div className="detail-page-layout">
          <LessonVideo />
          <div className="detail-page-content">
            <LessonDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonHeader;

export const VARIANT_CLASS_NAME = {
  primary: 'ui-primary',
  secondary: 'ui-secondary',
  link: 'ui-link',
  iconLink: 'ui-icon-link',
  outlined: 'ui-outlined',
};
export const SIZE_CLASS_NAME = {
  large: 'ui-large',
  small: 'ui-small',
};
export const COLOR_CLASS_NAME = {
  default: '',
  warning: 'ui-warning',
  success: 'ui-success',
  white: 'ui-white',
};

import { useState } from 'react';
import PropTypes from 'prop-types';

import Label from '~/components/ui/Label';
import ErrorMessage from '~/components/ui/ErrorMessage';
import Description from '~/components/ui/Description';
import { classNames } from '~/components/ui/utils';

export interface UiInputProps {
  id?: string;
  type: React.HTMLInputTypeAttribute;
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  description?: string;
  errorMessage?: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}
function Input({
  id,
  type,
  name,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  label,
  description,
  errorMessage,
  required,
  error,
  disabled,
}: UiInputProps) {
  const [localValue, setLocalValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event);
    } else {
      setLocalValue(event.currentTarget.value);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (onBlur) onBlur(event);
  };

  return (
    <div className="w-full">
      <Label
        id={id}
        label={label}
        error={error}
        after={(
          <>
            <Description description={description} />
            <input
              id={id}
              name={name}
              type={type}
              value={value || localValue}
              placeholder={placeholder}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
              required={required}
              className={classNames('ui-text-input', error && 'ui-error', disabled && 'ui-disabled')}
            />
            <ErrorMessage error={error} message={errorMessage} />
          </>
        )}
      />
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  value: null,
  placeholder: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  label: null,
  description: null,
  errorMessage: null,
  required: false,
  error: false,
  disabled: false,
};

export default Input;

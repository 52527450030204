import isArray from 'lodash/isArray';

import { Tag, TagData } from '~/models/Tag';
import { Course, CourseData } from '~/models/Course';
import { Unit, UnitData } from '~/models/Unit';
import { Lesson, LessonData } from '~/models/Lesson';

export interface TaggedData extends TagData {
  courses?: CourseData[];
  units?: UnitData[];
  lessons?: LessonData[];
}

export class Tagged extends Tag {
  courses: Course[];

  units: Unit[];

  lessons: Lesson[];

  constructor(data: TaggedData = {}) {
    super(data);
    this.courses = isArray(data.courses) ? data.courses.map(course => new Course(course)) : [];
    this.units = isArray(data.units) ? data.units.map(unit => new Unit(unit)) : [];
    this.lessons = isArray(data.lessons) ? data.lessons.map(lesson => new Lesson(lesson)) : [];
  }

  get itemsTagged(): number {
    return this.courses.length + this.units.length + this.lessons.length;
  }
}

export default Tagged;

import PropTypes from 'prop-types';
import { PlayCircle } from 'react-feather';

import { Lesson } from '~/models/Lesson';
import { classNames } from '~/components/ui/utils';
import { ReactComponent as CheckCircle } from '~/graphics/check-circle-gradient.svg';
import { LessonProps } from '~/components/lesson/Lesson';

function Icon({ lesson }: LessonProps) {
  const { completed } = lesson;
  return (
    <div className={classNames('lesson-icon-container', completed ? 'lesson-icon-completed' : 'lesson-icon-pristine')}>
      {completed ? <CheckCircle className="lesson-icon" /> : <PlayCircle className="lesson-icon" />}
    </div>
  );
}

Icon.propTypes = { lesson: PropTypes.instanceOf(Lesson).isRequired };

export default Icon;

export const APP_NAME = process.env.REACT_APP_NAME || '';
export const APP_SHORT_NAME = process.env.REACT_APP_SHORT_NAME || '';
export const APP_URL = process.env.REACT_APP_URL || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const SIGNUP_LINK = process.env.REACT_APP_SIGNUP_LINK || '';
export const PARENT_COMPANY_NAME = process.env.REACT_APP_PARENT_COMPANY_NAME || '';
export const COPYRIGHT_HOLDER = process.env.REACT_APP_COPYRIGHT_HOLDER || '';
export const COPYRIGHT_LINK = process.env.REACT_APP_COPYRIGHT_LINK || '';
export const CONTACT_US_LINK = process.env.REACT_APP_CONTACT_US_LINK || '';
export const TERMS_LINK = process.env.REACT_APP_TERMS_LINK || '';
export const PRIVACY_LINK = process.env.REACT_APP_PRIVACY_LINK || '';
export const WELCOME_VIDEO_URL = process.env.REACT_APP_WELCOME_VIDEO_URL || '';
export const CHECKOUT_VIDEO_URL = process.env.REACT_APP_CHECKOUT_VIDEO_URL || '';
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || '';
export const ENV_NAME = process.env.REACT_APP_ENV_NAME || '';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_ID = process.env.REACT_APP_ID || '';
export const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || '';
export const LAUNCHDARKLY_ANONYMOUS_HASH = process.env.REACT_APP_LAUNCHDARKLY_ANONYMOUS_HASH || '';
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || '';
export const ALGOLIA_KEY = process.env.REACT_APP_ALGOLIA_KEY || '';

export const DEFAULT_CAROUSEL_SLIDES_TO_SHOW = 4;
export const IMG_LOADING_EAGER = 'eager';
export const IMG_LOADING_LAZY = 'lazy';
export const HERO_SLIDE_PAUSE_MILLISECONDS = 6000;
export const SHORT_DESCRIPTION_LENGTH = 180;
export const NAVBAR_SCROLL_HIDE_THRESHOLD = 24;
export const API_TIMEOUT_MILLISECONDS = 20000;
export const UPLOADED_VIDEO_URL = 'amazonaws.com';

export const PATHS = {
  CATEGORY: '/category',
  TAG: '/tag',
  COURSE: '/course',
  UNIT: '/unit',
  LESSON: '/lesson',
  LOGIN: '/login',
  LOGIN_EXISTING_ACCOUNT: '/login/existing',
  LOGIN_PASSWORD_RESET: '/login/success',
  LOGIN_CANCELED_ACCOUNT: '/login/canceled',
  FORGOT: '/forgot',
  RESET: '/reset',
  SIGNUP: '/signup',
  SIGNUP_SUCCESS: '/signup/success',
  LOGOUT: '/logout',
  ACCOUNT: '/account',
  GROW_CHECKOUT: '/growwithsamcart',
  LAUNCH_CHECKOUT: '/launchwithsamcart',
  ADD_TO_ORDER: '/add',
  ORDER: '/order',
  SEARCH_RESULTS: '/search',
};

export const API_PATHS = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  FORGOT_PASSWORD: '/auth/password/recover',
  RESET_PASSWORD: '/auth/password/reset',
  SIGNUP: '/signup',
  VALIDATE_SIGNUP: '/cart',
  ENROLL: '/enroll',
  COURSES: '/courses',
  LESSON: '/lesson',
  CATEGORIES: '/categories',
  TAGS: '/tags',
  SEARCH: '/search',
  HERO_SLIDES: '/carousel',
  FEATURES: '/features',
  USERS: '/users',
  SUBSCRIPTION: '/subscription',
  SAMCART_ONBOARDING: '/onboardingLink',
  PRODUCTS: '/products',
  ALGOLIA_KEY: '/searchKey',
};

export const SEARCH_RESULT_TYPES = {
  category: {
    singular: 'Category',
    plural: 'Categories',
    path: PATHS.CATEGORY,
  },
  course: {
    singular: 'Course',
    plural: 'Courses',
    path: PATHS.COURSE,
  },
  tag: {
    singular: 'Tag',
    plural: 'Tags',
    path: PATHS.TAG,
  },
};

export const ARIA_ROLES = {
  combobox: 'combobox',
  option: 'option',
};

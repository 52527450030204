import { Link } from 'react-router-dom';

import { APP_NAME } from '~/lib/constants';
import { ReactComponent as LogoDarkMobile } from '~/graphics/brandmark-mobile-knockout.svg';
import { ReactComponent as LogoDark } from '~/graphics/brandmark-knockout.svg';

function Logo() {
  return (
    <Link to="/" className="nav-logo pl-4 lg:pl-6" aria-label={`${APP_NAME} homepage`}>
      <LogoDarkMobile className="block w-32 md:hidden" />
      <LogoDark className="hidden w-56 xl:w-64 md:block" />
    </Link>
  );
}

export default Logo;

export interface BasicUnitData {
  id?: string;
  slug?: string;
  name?: string;
  description?: string;
}
export class BasicUnit {
  id: string;

  slug: string;

  name: string;

  description: string;

  constructor(data: BasicUnitData = {}) {
    this.id = data.id || '';
    this.slug = data.slug || '';
    this.name = data.name || '';
    this.description = data.description || '';
  }
}

export default BasicUnit;

/* eslint-env node */
/* eslint-disable @typescript-eslint/no-var-requires, no-multi-spaces */

const autofill = require('tailwindcss-autofill');
const textFill = require('tailwindcss-text-fill');
const shadowFill = require('tailwindcss-shadow-fill');

module.exports = {
  content: ['./src/**/*.{html,js,jsx,ts,tsx}'],
  darkMode: 'class',
  theme: {
    screens: {
      xs: '0px',
      sm: '480px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    fontFamily: {
      display: ['Inter', 'Helvetica', 'Arial', 'sans-serif'],
      body: ['Inter', 'Helvetica', 'Arial', 'sans-serif'],
    },
    fontSize: {
      xs: '0.625rem',   // 10px
      sm: '0.75rem',    // 12px
      base: '0.875rem', // 14px
      lg: '1rem',       // 16px
      xl: '1.125rem',   // 20px
      '2xl': '1.5rem',  // 24px
      '3xl': '2.25rem', // 36px
      '4xl': '3rem',    // 48px
      '5xl': '4rem',    // 64px
    },
    extend: {
      backgroundImage: { auth: 'url("../graphics/auth-bg.jpg")' },
      colors: {
        gray: {
          100: '#F5F8FA',
          200: '#EFF1F3',
          300: '#DEE3E7',
          400: '#BDC7CF',
          500: '#9CABB7',
          600: '#7B8F9E',
          700: '#5A7386',
          800: '#233543',
          900: '#0B1A26',
        },
        blue: {
          100: '#F0F8FF',
          200: '#E0F2FF',
          300: '#B3DEFF',
          400: '#8CCDFF',
          500: '#66BDFF',
          600: '#1D96F3',
          700: '#046BD9',
          800: '#024291',
          900: '#012961',
        },
        purple: {
          100: '#F4F2FF',
          200: '#EAE5FF',
          300: '#D4CCFF',
          400: '#BDB0FF',
          500: '#9580FF',
          600: '#6A4DFF',
          700: '#4B17E6',
          800: '#200899',
          900: '#110066',
        },
        red: {
          100: '#FFE5E8',
          200: '#FFBFC4',
          300: '#FF99A1',
          400: '#FF737E',
          500: '#FF4D5B',
          600: '#F22435',
          700: '#D91636',
          800: '#B20925',
          900: '#99001A',
        },
        yellow: {
          100: '#FFF9E5',
          200: '#FFEFBF',
          300: '#FFE699',
          400: '#FFDC73',
          500: '#FFCF40',
          600: '#FFBE0D',
          700: '#F2B50C',
          800: '#E59D0B',
          900: '#BF7F00',
        },
        aqua: {
          100: '#E8FAF9',
          200: '#CFFAF8',
          300: '#A6EDEB',
          400: '#7BE0DD',
          500: '#41D9D4',
          600: '#00BFB9',
          700: '#00A39E',
          800: '#068080',
          900: '#0A6666',
        },
        green: {
          100: '#E6FAF3',
          200: '#CEF5E8',
          300: '#A9EED7',
          400: '#7AE2BF',
          500: '#34E1A7',
          600: '#0ACC8B',
          700: '#089B6A',
          800: '#096F4E',
          900: '#0A583F',
        },
        dark: {
          success: '#1D3A3E',
          error: '#322834',
        },
      },
      minHeight: { px: '1px' },
      maxWidth: { limit: '1440px' },
      lineHeight: { 0: '0' },
      spacing: {
        140: '35rem',
        '15/100': '15%',
        '1/5': '20%',
        '3/10': '30%',
        '80vh': '80vh',
      },
      transitionProperty: { width: 'width' },
      animation: {
        'slide-out-left': 'slide-out-left 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'slide-in-left': 'slide-in-left 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'slide-out-right': 'slide-out-right 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'slide-in-right': 'slide-in-right 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'expand-search-mobile': 'expand-search-mobile 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'collapse-search-mobile': 'collapse-search-mobile 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both',
        'load-bar-increase': 'load-bar-increase 1.5s infinite',
        'load-bar-decrease': 'load-bar-decrease 2s 0.5s infinite',
        'spin-slow': 'spin 3s linear infinite',
      },
      keyframes: {
        'load-bar-increase': {
          from: { left: '-5%', width: '5%' },
          to: { left: '130%', width: '100%' },
        },
        'load-bar-decrease': {
          from: { left: '-80%', width: '80%' },
          to: { left: '110%', width: '10%' },
        },
        'expand-search-mobile': {
          '0%': { transform: 'translateX(0)' },
          '100%': {
            transform: 'translateX(3rem)',
            width: 'calc(100vw - 5rem)',
          },
        },
        'collapse-search-mobile': {
          '0%': { transform: 'translateX(3rem)', width: 'calc(100vw - 5rem)' },
          '100%': { transform: 'translateX(0)' },
        },
        'slide-out-left': {
          '0%': {
            transform: 'translateX(0)',
            opacity: 1,
          },
          '100%': {
            transform: 'translateX(-9rem)',
            opacity: 0,
          },
        },
        'slide-in-left': {
          '0%': {
            transform: 'translateX(-9rem)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateX(0)',
            opacity: 1,
          },
        },
        'slide-out-right': {
          '0%': {
            transform: 'translateX(0)',
            opacity: 1,
          },
          '100%': {
            transform: 'translateX(1000px)',
            opacity: 0,
          },
        },
        'slide-in-right': {
          '0%': {
            transform: 'translateX(3rem)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateX(0)',
            opacity: 1,
          },
        },
      },
      boxShadow: { dark: '0px 1px 5px 0px rgba(245, 248, 250, 0.15)' },
    },
  },
  plugins: [autofill, textFill, shadowFill],
};

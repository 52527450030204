import { UserData } from '~/api/auth';

export class User {
  id: string;

  uuid: string;

  marketplaceOwnerUuid: string;

  firstName: string;

  lastName: string;

  email: string;

  launchDarklyHash: string;

  createdAt?: Date;

  constructor(data: UserData = {}) {
    this.id = data.id?.toString() || '';
    this.uuid = data.userUuid || '';
    this.marketplaceOwnerUuid = data.mkOwnerUuid || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.email = data.email || '';
    this.launchDarklyHash = data.launchDarklyHash || '';
    this.createdAt = data.userCreatedAt?.date ? new Date(data.userCreatedAt?.date) : undefined;
  }

  get signedIn(): boolean {
    return !!this.uuid.length;
  }

  get accountUuid(): string {
    return this.marketplaceOwnerUuid.length
      ? this.marketplaceOwnerUuid
      : this.uuid;
  }

  get hasMarketplace(): boolean {
    return !!this.marketplaceOwnerUuid.length;
  }
}

export default User;

import { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import UnitSelect from '~/components/unit/UnitSelect';
import UnitHeader from '~/components/unit/UnitHeader';
import UnitLessons from '~/components/unit/UnitLessons';
import { BasicUnit } from '~/models/BasicUnit';
import { useCourseContext } from '~/contexts/Course';
import UnitContext from '~/contexts/Unit';

export interface CourseUnitsProps {
  unitSlug?: string;
}

// eslint-disable-next-line react/display-name
const CourseUnits = forwardRef<HTMLDivElement, CourseUnitsProps>((props, ref) => {
  const { units, nextLesson } = useCourseContext();
  const { unitSlug } = props;
  const [selectedUnit, setSelectedUnit] = useState<BasicUnit>(new BasicUnit());

  useEffect(() => {
    let initialUnit: BasicUnit;
    if (unitSlug) {
      initialUnit = units.find(({ slug }) => slug === unitSlug) || units[0];
    } else {
      initialUnit = nextLesson.unit;
    }
    setSelectedUnit(initialUnit);
  }, [unitSlug]);

  const unit = units.find(({ id }) => id === selectedUnit?.id) || units[0];

  return (
    <div className="course-units page-container" ref={ref}>
      <div className="detail-page-layout">
        <div className="detail-page-content">
          <UnitSelect
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
          <UnitContext.Provider value={unit}>
            <UnitHeader />
            <UnitLessons />
          </UnitContext.Provider>
        </div>
      </div>
    </div>
  );
});

CourseUnits.propTypes = { unitSlug: PropTypes.string };

CourseUnits.defaultProps = { unitSlug: undefined };

export default CourseUnits;

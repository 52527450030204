function GrowFeatureList() {
  return (
    <>
      <h2 className="checkout-card-feature-list-header">All Launch features, plus:</h2>
      <ul className="checkout-card-feature-list-wrapper">
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">3 admin users</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Enhanced</span>
          <span>integrations</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">1-day</span>
          <span>email support</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Order bump</span>
          <span>collections</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span>Post-purchase</span>
          <span className="font-bold">upsells</span>
        </li>
      </ul>
    </>
  );
}

export default GrowFeatureList;

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Category } from '~/models/Category';
import Button from '~/components/ui/Button';
import Carousel from '~/components/carousel/Carousel';
import { PATHS } from '~/lib/constants';
import { BreakpointSetting, ImgLoadingAttribute } from '~/api/data';

export interface CategoryCarouselProps {
  category: Category;
  slidesToShow: number;
  breakpoints: BreakpointSetting[];
  imgLoading: ImgLoadingAttribute;
}

function CategoryCarousel({
  category,
  slidesToShow,
  breakpoints,
  imgLoading,
}: CategoryCarouselProps) {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-baseline mb-4" data-testid="category-carousel-header">
        <h4 className="h5 md:h4">{category.name}</h4>
        <div className="inline-block">
          <Button
            variant="link"
            label="View All"
            onClick={() => navigate(`${PATHS.CATEGORY}/${category.slug}`)}
          />
        </div>
      </div>
      <div className={`carousel carousel-slides-${slidesToShow}`} data-testid="category-carousel-slides">
        <Carousel
          courses={category.courses}
          slidesToShow={slidesToShow}
          breakpoints={breakpoints}
          imgLoading={imgLoading}
        />
      </div>
    </>
  );
}

CategoryCarousel.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired,
  breakpoints: PropTypes.arrayOf(PropTypes.shape({
    breakpoint: PropTypes.number.isRequired,
    slides: PropTypes.number.isRequired,
  })).isRequired,
  slidesToShow: PropTypes.number.isRequired,
  imgLoading: PropTypes.string.isRequired,
};

export default CategoryCarousel;

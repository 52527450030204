import PropTypes from 'prop-types';

import Thumbnail from '~/components/course/Thumbnail';
import Name from '~/components/shared/Name';
import Description from '~/components/shared/Description';
import Tags from '~/components/shared/Tags';
import { useCourseContext } from '~/contexts/Course';
import { ImgLoadingAttribute } from '~/api/data';

export interface CourseCardContentProps {
  onNavigate(): void;
  imgLoading: ImgLoadingAttribute;
}

function CardContent({ onNavigate, imgLoading }: CourseCardContentProps) {
  const {
    name,
    description,
    tags,
    thumbnail,
  } = useCourseContext();

  return (
    <div className="course-card" data-testid="course-card">
      <div
        className="link"
        role="button"
        onClick={onNavigate}
        onKeyPress={onNavigate}
        tabIndex={0}
      >
        <Thumbnail
          image={thumbnail}
          fallbackAlt={name}
          imgLoading={imgLoading}
        />
        <Name name={name} />
        <Description description={description} truncate />
      </div>
      <Tags tags={tags} />
    </div>
  );
}

CardContent.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  imgLoading: PropTypes.string.isRequired,
};

export default CardContent;

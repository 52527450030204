function LoginHeader() {
  return (
    <>
      <h3 className="mb-2">Welcome back!</h3>
      <p>Use your login credentials to access your account.</p>
    </>
  );
}

export default LoginHeader;

import { useLocation } from 'react-router-dom';

import SignedIn from '~/components/layout/SignedIn';
import SignedOut from '~/components/layout/SignedOut';
import { PATHS } from '~/lib/constants';
import { useAuth } from '~/contexts/Auth';

function Layout() {
  const location = useLocation();
  const auth = useAuth();

  const signedOutRoutesPotentiallyAuthenticated = [
    PATHS.SIGNUP_SUCCESS,
    PATHS.LOGIN,
    PATHS.LOGIN_EXISTING_ACCOUNT,
  ].includes(location.pathname);

  return auth.user.signedIn && !signedOutRoutesPotentiallyAuthenticated ? <SignedIn /> : <SignedOut />;
}

export default Layout;

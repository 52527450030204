import { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { Course } from '~/models/Course';
import Card from '~/components/course/Card';
import NextArrow from '~/components/carousel/NextArrow';
import PrevArrow from '~/components/carousel/PrevArrow';
import { DEFAULT_CAROUSEL_SLIDES_TO_SHOW, IMG_LOADING_LAZY } from '~/lib/constants';
import { BreakpointSetting, ImgLoadingAttribute } from '~/api/data';
import { CourseListProps } from '~/types/components';

export interface CarouselProps extends CourseListProps {
  slidesToShow?: number;
  breakpoints?: BreakpointSetting[];
  imgLoading?: ImgLoadingAttribute;
}

function Carousel({
  breakpoints,
  slidesToShow,
  courses,
  imgLoading,
}: CarouselProps) {
  const [dragging, setDragging] = useState(false);
  const responsive = breakpoints ? breakpoints.map(({ breakpoint, slides }) => ({
    breakpoint,
    settings: {
      slidesToShow: slides,
      slidesToScroll: slides,
    },
  })) : [];

  return (
    <Slider
      infinite={false}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToShow}
      responsive={responsive}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      beforeChange={() => setDragging(true)}
      afterChange={() => setDragging(false)}
    >
      {courses.map(course => (
        <Card
          key={course.id}
          course={course}
          disabled={dragging}
          imgLoading={imgLoading}
        />
      ))}
    </Slider>
  );
}

Carousel.propTypes = {
  breakpoints: PropTypes.arrayOf(PropTypes.shape({
    breakpoint: PropTypes.number.isRequired,
    slides: PropTypes.number.isRequired,
  })),
  slidesToShow: PropTypes.number,
  courses: PropTypes.arrayOf(PropTypes.instanceOf(Course)).isRequired,
  imgLoading: PropTypes.string,

};

Carousel.defaultProps = {
  breakpoints: [],
  slidesToShow: DEFAULT_CAROUSEL_SLIDES_TO_SHOW,
  imgLoading: IMG_LOADING_LAZY,
};

export default Carousel;

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import BannerContent from '~/components/shared/BannerContent';

export interface BannerProps {
  CTALink?: string;
  label: string;
  image: string;
  imageClassName: string;
  imageAltText: string;
}

function Banner({
  CTALink,
  label,
  image,
  imageClassName,
  imageAltText,
}: BannerProps) {
  const path = useLocation().pathname;
  if (CTALink == null || path !== '/') {
    return null;
  }

  return (
    <a href={CTALink} aria-label={label} target="_blank" className="block" rel="noreferrer">
      <BannerContent
        label={label}
        image={image}
        imageAltText={imageAltText}
        imageClassName={imageClassName}
      />
    </a>
  );
}

Banner.propTypes = {
  CTALink: PropTypes.string,
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageClassName: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
};

Banner.defaultProps = { CTALink: undefined };

export default Banner;

import Name from '~/components/shared/Name';
import Description from '~/components/shared/Description';
import Tags from '~/components/shared/Tags';
import { useUnitContext } from '~/contexts/Unit';

function UnitHeader() {
  const { name, description, tags } = useUnitContext();

  return (
    <div className="unit-header">
      <Name name={name} />
      <Description description={description} />
      <Tags tags={tags} />
    </div>
  );
}

export default UnitHeader;

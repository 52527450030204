import isArray from 'lodash/isArray';

import { Course, CourseData } from '~/models/Course';
import { BasicCategory, BasicCategoryData } from '~/models/BasicCategory';

export interface CategoryData extends BasicCategoryData {
  courses?: CourseData[];
}

export class Category extends BasicCategory {
  courses: Course[];

  constructor(data: CategoryData = {}) {
    super(data);
    this.courses = isArray(data.courses) ? data.courses.map(course => new Course(course)) : [];
  }
}

export default Category;

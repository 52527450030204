import {
  useState, useEffect, useMemo, ReactNode,
} from 'react';
import { DateTime } from 'luxon';
import {
  CheckCircle,
  AlertTriangle,
  Settings,
} from 'react-feather';

import { formatPrice } from '~/lib/utils';
import Button from '~/components/ui/Button';
import creatoru from '~/graphics/creatoru.png';
import creatorulive from '~/graphics/creatoru-live.png';
import CreditCard from '~/components/account/CreditCard';
import Status from '~/components/account/Status';
import { useAccount } from '~/contexts/Account';
import PayPal from '~/components/account/PayPal';
import ManageAccountModal from '~/components/account/ManageAccountModal';
import ReactivateAccountModal from '~/components/account/ReactivateAccountModal';
import Alert, { AlertClassName } from '~/components/account/Alert';
import { Canceled, ScheduledCancel } from '~/components/account/AlertMessages';
import useDocumentTitle from '~/hooks/title';

function AccountPage() {
  const { account: accountData, updateAccount } = useAccount();
  useDocumentTitle('Manage Account');

  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<ReactNode>(<span />);
  const [alertClassName, setAlertClassname] = useState<AlertClassName>('success');
  const [alertCanClose, setAlertCanClose] = useState(true);

  const showAlert = (className: AlertClassName, message: ReactNode, canClose = true) => {
    setAlertOpen(true);
    setAlertMessage(message);
    setAlertClassname(className);
    setAlertCanClose(canClose);
  };

  const account = useMemo(() => accountData, [accountData]);

  useEffect(() => {
    if (account.isCanceled) {
      showAlert('warning', <Canceled onClick={() => setReactivateModalOpen(true)} />, false);
    } else if (account.scheduledCancelDate) {
      const formattedCancelDate = DateTime.fromSQL(account.scheduledCancelDate).toFormat('DDD');
      showAlert('warning', <ScheduledCancel onClick={() => setReactivateModalOpen(true)} cancelDate={formattedCancelDate} />, false);
    }
  }, [account.isCanceled, account.scheduledCancelDate]);

  const icon = alertClassName === 'success' ? <CheckCircle className="text-green-400" /> : <AlertTriangle className="text-red-400" />;

  const activeText = (
    <p className="text-gray-400">
      Next payment of
      {' '}
      {`$${formatPrice(account.price)}`}
      {' '}
      on
      {' '}
      {DateTime.fromSQL(account.rebillingDate).toFormat('DDD')}
    </p>
  );
  const scheduledText = (
    <p className="text-red-500">
      Will not renew after
      {' '}
      {DateTime.fromSQL(account.scheduledCancelDate).toFormat('DDD')}
    </p>
  );
  const canceledText = (
    <p className="text-gray-400">
      Canceled on
      {' '}
      {DateTime.fromSQL(account.rebillingDate).toFormat('DDD')}
    </p>
  );
  return (
    <div className="min-h-screen pt-14 xl:pt-16">
      <div className="account-page-layout">
        <h2 className="mb-16">Account</h2>
        <Alert
          open={alertOpen}
          icon={icon}
          handleClose={() => setAlertOpen(false)}
          message={alertMessage}
          className={alertClassName}
          canClose={alertCanClose}
        />
        <h4>My Subscription</h4>
        <div className="subscription">
          <div className="md:w-1/2 w-full">
            {account.tier === 'creatoru'
              ? <img src={creatoru} alt="CreatorU" className="subscription-logo" />
              : <img src={creatorulive} alt="CreatorU Live" className="subscription-logo" />}
            <div className="account-tier">
              <h4>{account.tier === 'creatoru' ? 'CreatorU' : 'CreatorU Live'}</h4>
              <Status status={account.status} />
            </div>
            <p>
              Since
              {' '}
              {DateTime.fromSQL(account.startDate).toFormat('MMM y')}
              {' '}
              |
              {' '}
              <span className="capitalize">{account.frequency}</span>
              &nbsp;Billing
            </p>
            {account.isActive && !account.scheduledCancelDate && activeText}
            {account.scheduledCancelDate && scheduledText}
            {account.isCanceled && canceledText}
          </div>
          <div className="w-50 flex-initial mb-4">
            {(!account.isActive || account.scheduledCancelDate) ? (
              <Button
                leadingIcon={<Settings />}
                label="Reactivate Subscription"
                type="button"
                variant="iconLink"
                color="success"
                size="large"
                onClick={() => setReactivateModalOpen(true)}
              />
            ) : (
              <Button
                leadingIcon={<Settings />}
                label="Manage Subscription"
                type="button"
                variant="iconLink"
                color="success"
                size="large"
                onClick={() => setManageModalOpen(true)}
              />
            )}
          </div>
        </div>
        <div className="payment-details">
          <div className="w-1/2">
            <h4>Payment Details</h4>
            <div>
              {account.paymentSourceType === 'paypal'
                ? <PayPal />
                : (
                  <CreditCard
                    brand={account.card.brand}
                    last4={account.card.last4}
                    expirationDate={account.card.expirationDate}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <ManageAccountModal
        open={manageModalOpen}
        handleClose={() => setManageModalOpen(false)}
        handleOpenReactivateModal={() => setReactivateModalOpen(true)}
        setAccount={updateAccount}
        showAlert={showAlert}
      />
      <ReactivateAccountModal
        open={reactivateModalOpen}
        handleClose={() => setReactivateModalOpen(false)}
        setAccount={updateAccount}
        canceled={account.isCanceled}
        price={account.price}
        rebillingDate={account.rebillingDate}
        showAlert={showAlert}
      />
    </div>
  );
}

export default AccountPage;

import PropTypes from 'prop-types';

import { Lesson as LessonModel } from '~/models/Lesson';
import Lesson from '~/components/lesson/Lesson';

export interface LessonListProps {
  lessons: LessonModel[];
}

function TaggedLessons({ lessons }: LessonListProps) {
  if (!lessons.length) {
    return null;
  }

  return (
    <div className="px-6 lg:px-12">
      <h4 className="mb-4">Lessons</h4>
      {lessons.map(lesson => <Lesson key={lesson.id} lesson={lesson} />)}
    </div>
  );
}

TaggedLessons.propTypes = { lessons: PropTypes.arrayOf(PropTypes.instanceOf(LessonModel)).isRequired };

export default TaggedLessons;

import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { AuthProvider } from '~/contexts/Auth';
import ThemeProvider from '~/providers/theme';
import PageRoutes from '~/PageRoutes';
import launchdarklyConfig from '~/providers/launch-darkly';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <PageRoutes />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default withLDProvider(launchdarklyConfig)(App);

import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';

import { API_PATHS, PATHS } from '~/lib/constants';
import { postRequest } from '~/api';
import { ErrorData } from '~/types/data';

export default async function purchaseWithExistingCard(productId: number, userId: string): Promise<string> {
  try {
    const payload = { productId };
    const url = `${API_PATHS.USERS}/${userId}${PATHS.ORDER}${PATHS.ADD_TO_ORDER}`;
    const response = await postRequest(url, payload);
    return response.data.data.redirect ?? '';
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('productId', { productId });
    });
    return Promise.reject(error.response?.data.error || 'Unable to locate account.');
  }
}

import {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';

import { Lesson, LessonData } from '~/models/Lesson';
import { Course, CourseData } from '~/models/Course';
import { getLesson, getCourse, updateLesson } from '~/api/data';
import LessonContext from '~/contexts/Lesson';
import CourseContext from '~/contexts/Course';
import PageLoading from '~/components/layout/PageLoading';
import LessonHeader from '~/components/lesson/LessonHeader';
import CourseUnits from '~/components/course/CourseUnits';
import Breadcrumbs from '~/components/shared/Breadcrumbs';
import { PATHS } from '~/lib/constants';
import useDocumentTitle from '~/hooks/title';

type LessonRouteParams = {
  courseSlug: string;
  unitSlug: string;
  lessonSlug: string;
}

function LessonPage() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { courseSlug, unitSlug, lessonSlug } = useParams<LessonRouteParams>();

  const [lessonData, setLessonData] = useState<LessonData>();
  const [courseData, setCourseData] = useState<CourseData>();

  useEffect(() => {
    async function getData() {
      if (lessonSlug && courseSlug) {
        const response = await getLesson(lessonSlug, courseSlug);
        const completed = await updateLesson(new Lesson(response));
        setLessonData({
          ...response,
          completed,
        });
      }
    }
    getData();
  }, [lessonSlug, courseSlug]);

  useEffect(() => {
    async function getData() {
      if (lessonSlug && courseSlug) {
        const response = await getCourse(courseSlug);
        setCourseData(response);
      }
    }
    getData();
  }, [lessonSlug, courseSlug]);

  const lesson = useMemo(() => new Lesson(lessonData), [lessonData]);
  const course = useMemo(() => new Course(courseData), [courseData]);

  useDocumentTitle(lesson.name);

  if (!lesson.id.length || !course.id.length) {
    return <PageLoading />;
  }

  const links = [
    {
      url: `${PATHS.COURSE}/${course.slug}`,
      label: course.name,
    },
    {
      url: `${PATHS.COURSE}/${course.slug}${PATHS.UNIT}/${lesson.unit.slug}${PATHS.LESSON}/${lesson.slug}`,
      label: lesson.name,
    },
  ];
  if (course.category.slug.length) {
    links.unshift({
      url: `${PATHS.CATEGORY}/${course.category.slug}`,
      label: course.category.name,
    });
  }

  return (
    <CourseContext.Provider value={course}>
      <LessonContext.Provider value={lesson}>
        <div className="min-h-screen pt-14 xl:pt-16">
          <Breadcrumbs links={links} />
          <LessonHeader />
          <CourseUnits unitSlug={unitSlug} ref={sectionRef} />
        </div>
      </LessonContext.Provider>
    </CourseContext.Provider>
  );
}

export default LessonPage;

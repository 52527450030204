import { ReactNode } from 'react';
import { ThemeProvider as ToggleThemeProvider } from '@samcart/design.theme.provider';
import { darkTheme } from '@samcart/design.theme.dark';

function ThemeProvider({ children }: {
  children: ReactNode;
}) {
  return (
    <ToggleThemeProvider
      theme={darkTheme}
    >
      {children}
    </ToggleThemeProvider>
  );
}

export default ThemeProvider;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Input from '~/components/ui/Input';
import Button from '~/components/ui/Button';
import ErrorMessage from '~/components/ui/ErrorMessage';
import { User } from '~/models/User';
import LinearLoadBar from '~/components/ui/LinearLoadBar';
import PasswordFields from '~/components/auth/PasswordFields';
import { PATHS } from '~/lib/constants';

export interface CreateAccountFormProps {
  user: User;
  password: string;
  confirmPassword: string;
  pristine: boolean;
  error: string;
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  handleChangePassword(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeConfirmPassword(event: React.ChangeEvent<HTMLInputElement>): void;
  processing: boolean;
}

function CreateAccountForm({
  user,
  password,
  confirmPassword,
  pristine,
  error,
  handleSubmit,
  handleChangePassword,
  handleChangeConfirmPassword,
  processing,
}: CreateAccountFormProps) {
  const [requirementError, setRequirementError] = useState(false);

  const passwordsMatch = (): boolean => !!password.length && !!confirmPassword.length && password === confirmPassword;

  return (
    <>
      <h3 className="mb-2">Sign up for CreatorU</h3>

      <form onSubmit={handleSubmit} aria-label="Create account form">
        <div className="pt-6">
          <Input
            disabled
            id="firstName"
            label="First name"
            name="firstName"
            type="text"
            value={user.firstName}
          />
        </div>

        <div className="pt-6">
          <Input
            disabled
            id="lastName"
            label="Last name"
            name="lastName"
            type="text"
            value={user.lastName}
          />
        </div>

        <div className="pt-6">
          <Input
            disabled
            id="email"
            label="Email address"
            name="email"
            type="text"
            value={user.email}
          />
        </div>

        <PasswordFields
          password={password}
          confirmPassword={confirmPassword}
          pristine={pristine}
          error={error}
          handleChangePassword={handleChangePassword}
          handleChangeConfirmPassword={handleChangeConfirmPassword}
          requirementError={requirementError}
          setRequirementError={setRequirementError}
          processing={processing}
        />

        <div className="pt-6">
          <Button
            type="submit"
            label="Create account"
            disabled={pristine || !passwordsMatch() || requirementError}
          />
          {processing && (
            <div className="-mt-1 rounded-b-lg h-1 overflow-hidden">
              <LinearLoadBar />
            </div>
          )}
        </div>

        <ErrorMessage error={pristine && !!error.length} message={error} />

        <p className="pt-7">
          Already registered?
          {' '}
          <Link to={PATHS.LOGIN}>Sign in</Link>
        </p>
      </form>
    </>
  );
}

CreateAccountForm.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  handleChangeConfirmPassword: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default CreateAccountForm;

export function Canceled({ onClick }: { onClick(): void }) {
  return (
    <span>
      You&apos;ve canceled your subscription.
      You can
      {' '}
      <button onClick={onClick} className="ui-inline-button">reactivate your subscription</button>
      {' '}
      at any time.
    </span>
  );
}

export function ScheduledCancel({ onClick, cancelDate }: { onClick(): void, cancelDate: string }) {
  return (
    <span>
      You&apos;ve canceled your subscription.
      You will lose access on
      {' '}
      {cancelDate}
      .
      You can
      {' '}
      <button onClick={onClick} className="ui-inline-button">reactivate your subscription</button>
      {' '}
      at any time.
    </span>
  );
}

export function Reactivated() {
  return <span>Your CreatorU subscription has been successfully reactivated!</span>;
}

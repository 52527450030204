function ForgotSuccess() {
  return (
    <>
      <h3 className="mb-2">Email sent</h3>
      <p className="mb-4">Follow the instructions provided in the email to reset your password.</p>
      <p className="mb-4">If you didn&apos;t receive an email, please check your spam folder or try again.</p>
    </>
  );
}

export default ForgotSuccess;

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Lesson as LessonModel } from '~/models/Lesson';
import Icon from '~/components/lesson/Icon';
import Tags from '~/components/shared/Tags';
import { PATHS } from '~/lib/constants';

export interface LessonProps {
  lesson: LessonModel;
}

function Lesson({ lesson }: LessonProps) {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(`${PATHS.COURSE}/${lesson.course.slug}${PATHS.UNIT}/${lesson.unit.slug}${PATHS.LESSON}/${lesson.slug}`);

  return (
    <div
      className="lesson-link"
      role="button"
      onClick={handleNavigate}
      onKeyPress={handleNavigate}
      tabIndex={0}
    >
      <Icon lesson={lesson} />
      <div className="lesson-info">
        <h6 className={lesson.completed ? 'completed' : 'pristine'}>{lesson.name}</h6>
        <Tags tags={lesson.tags} />
      </div>
    </div>
  );
}

Lesson.propTypes = { lesson: PropTypes.instanceOf(LessonModel).isRequired };

export default Lesson;

export enum CreditCardBrandsEnum {
  visa = 'visa',
  mastercard = 'mastercard',
  americanExpress = 'american_express',
  discover = 'discover',
  jcb = 'jcb',
  dinersClub = 'diners_club',
  paypal = 'paypal',
  unknown = 'unknown',
}
export enum PaymentSourceTypeEnum {
  card = 'card',
  paypal = 'paypal',
}
export type CreditCardBrands = `${CreditCardBrandsEnum}`
export type PaymentSourceType = `${PaymentSourceTypeEnum}`;

export interface CreditCardData {
  last4?: string,
  brand?: CreditCardBrands,
  expirationDate?: string,
}

export enum AccountStatusEnum {
  active = 'Active',
  canceled = 'Canceled'
}

export type AccountStatus = `${AccountStatusEnum}` | '';

export interface AccountData {
  startDate?: string,
  tier?: string,
  status?: AccountStatus,
  paymentSourceType?: PaymentSourceType,
  card?: CreditCardData,
  rebillingDate?: string,
  scheduledCancelDate?: string,
  price?: number,
  frequency?: string,
}

export class Account {
  startDate: string;

  tier: string;

  status: AccountStatus;

  paymentSourceType: PaymentSourceType;

  card: CreditCardData;

  rebillingDate: string;

  scheduledCancelDate: string;

  frequency: string;

  price: number;

  constructor(data: AccountData = {}) {
    const { card = {} } = data;
    this.startDate = data.startDate || '';
    this.tier = data.tier || '';
    this.status = data.status || '';
    this.paymentSourceType = data.paymentSourceType || 'card';
    this.card = data.card || {
      last4: card.last4,
      brand: card.brand,
      expirationDate: card.expirationDate,
    };
    this.rebillingDate = data.rebillingDate || '';
    this.scheduledCancelDate = data.scheduledCancelDate || '';
    this.frequency = data.frequency || '';
    this.price = data.price || 0;
  }

  get isActive(): boolean {
    return this.status === AccountStatusEnum.active;
  }

  get isCanceled(): boolean {
    return this.status === AccountStatusEnum.canceled;
  }
}

export default Account;

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { validEmailAddress } from '~/lib/utils';
import ForgotForm from '~/components/auth/ForgotForm';
import ForgotSuccess from '~/components/auth/ForgotSuccess';
import { PATHS } from '~/lib/constants';
import { useAuth } from '~/contexts/Auth';
import { forgotPassword } from '~/api/auth';
import useDocumentTitle from '~/hooks/title';

function ForgotPage() {
  const [email, setEmail] = useState('');
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  useDocumentTitle('Forgot password');

  useEffect(() => {
    if (auth.user.signedIn) {
      navigate('/');
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setPristine(true);
    setProcessing(true);
    if (!validEmailAddress(email)) {
      setError(true);
      setProcessing(false);
    } else {
      forgotPassword(email).then(() => {
        setError(false);
        setSuccess(true);
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPristine(false);
    setError(false);
    setEmail(event.currentTarget.value);
  };

  return (
    <>
      {success ? (
        <ForgotSuccess />
      ) : (
        <ForgotForm
          email={email}
          pristine={pristine}
          error={error}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          processing={processing}
        />
      )}
      <p className="pt-4"><Link to={PATHS.LOGIN}>Back to login</Link></p>
    </>
  );
}

export default ForgotPage;

import { Link } from 'react-router-dom';

import { ReactComponent as LogoDark } from '~/graphics/brandmark-knockout.svg';
import { ReactComponent as TV } from '~/graphics/tv.svg';
import { APP_NAME } from '~/lib/constants';
import useDocumentTitle from '~/hooks/title';

function PageNotFound() {
  useDocumentTitle('Page not found');

  return (
    <div className="dark bg-gray-900 min-h-screen py-14 px-4">
      <Link to="/" className="block mx-auto w-56 lg:w-80 xl:w-96 mb-10 lg:mb-16" aria-label={`${APP_NAME} homepage`}>
        <LogoDark className="w-56 lg:w-80 xl:w-96" />
      </Link>
      <h1 className="text-center mb-6 h4 md:h3 lg:h2">Something Went Wrong!</h1>
      <h4 className="text-center mb-16 lg:mb-24 h6 md:h5 lg:h4">The page you are looking for was not found.</h4>
      <TV className="block mx-auto w-56 lg:w-80 xl:w-96" />
    </div>
  );
}

export default PageNotFound;

import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchInput from '~/components/nav/SearchInput';
import OpenSearchButton from '~/components/nav/OpenSearchButton';
import ClearSearchButton from '~/components/nav/ClearSearchButton';
import { SearchOpenProp } from '~/components/nav/NavBar';
import useSearch from '~/hooks/search';

export interface SearchProps {
  open?: SearchOpenProp;
  setOpen: React.Dispatch<React.SetStateAction<SearchOpenProp>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

function Search({
  open,
  setOpen,
  value,
  setValue,
}: SearchProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { data, loading } = useSearch(value);

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (open && containerRef.current && !containerRef.current.contains(event.target as Node)) {
        if (searchInputRef.current) {
          searchInputRef.current.blur();
        }
        setOpen(false);
        setValue('');
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  const focusInput = () => {
    if (searchInputRef.current) searchInputRef.current.focus();
  };

  const handleOpen = () => {
    focusInput();
    setOpen(true);
  };

  const handleClear = () => {
    setValue('');
    focusInput();
  };

  return (
    <div className="nav-search" ref={containerRef}>
      <OpenSearchButton open={open} onClick={handleOpen} />
      <SearchInput
        ref={searchInputRef}
        data={data}
        loading={loading}
        value={value}
        setValue={setValue}
        setOpen={setOpen}
      />
      <ClearSearchButton onClick={handleClear} />
    </div>
  );
}

Search.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
Search.defaultProps = { open: undefined };

export default Search;

import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';

import { API_PATHS } from '~/lib/constants';
import { getRequest, postRequest } from '~/api';
import { ErrorData } from '~/types/data';
import { AccountData } from '~/models/Account';

export async function getAccountData(uuid: string): Promise<AccountData> {
  try {
    const { data: { data } = {} } = await getRequest(`${API_PATHS.USERS}/${uuid}${API_PATHS.SUBSCRIPTION}`);
    return data;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('userUuidDetails', { uuid });
    });
    return Promise.reject(error.response?.data.error || 'Unable to locate account.');
  }
}

export async function cancelAccount(uuid: string): Promise<AccountData> {
  try {
    const { data: { data: accountData = {} } = {} } = await postRequest(`${API_PATHS.USERS}/${uuid}${API_PATHS.SUBSCRIPTION}/cancel`);
    return accountData;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('userUuidDetails', { uuid });
    });
    return Promise.reject(error.response?.data.error || 'Unable to locate account.');
  }
}

export async function reactivateAccount(uuid: string): Promise<AccountData> {
  try {
    const { data: { data: accountData = {} } = {} } = await postRequest(`${API_PATHS.USERS}/${uuid}${API_PATHS.SUBSCRIPTION}/reactivate`);
    return accountData;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('userUuidDetails', { uuid });
    });
    return Promise.reject(error.response?.data.error || 'Unable to locate account.');
  }
}

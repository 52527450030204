import Button from '~/components/ui/Button';
import {
  APP_NAME,
  COPYRIGHT_HOLDER,
  COPYRIGHT_LINK,
  CONTACT_US_LINK,
  TERMS_LINK,
  PRIVACY_LINK,
} from '~/lib/constants';
import { ReactComponent as LogoDarkMobile } from '~/graphics/brandmark-footer-knockout.svg';

function Footer() {
  const navigate = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <footer>
      <div className="flex flex-col md:flex-row md:items-center mx-auto px-6 py-4">
        <a aria-label={`${APP_NAME} copyright`} href={COPYRIGHT_LINK} target="_blank" rel="noreferrer" className="mx-auto mb-4 md:ml-0 md:mb-0 md:mr-6">
          <LogoDarkMobile className="h-8 " />
        </a>

        <p className="text-center md:text-left mb-6 md:mb-0 md:mr-4">
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          {COPYRIGHT_HOLDER}
        </p>

        <div className="hidden md:grow md:block" />

        <div className="inline-block mb-4 md:mb-0 md:mr-4">
          <Button
            variant="link"
            size="small"
            label="Contact us"
            onClick={() => navigate(CONTACT_US_LINK)}
          />
        </div>

        <div className="inline-block mb-4 md:mb-0 md:mr-4">
          <Button
            variant="link"
            size="small"
            label="Terms &amp; conditions"
            onClick={() => navigate(TERMS_LINK)}
          />
        </div>

        <div className="inline-block">
          <Button
            variant="link"
            size="small"
            label="Privacy policy"
            onClick={() => navigate(PRIVACY_LINK)}
          />
        </div>

      </div>
    </footer>
  );
}

export default Footer;

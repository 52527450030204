import { useNavigate } from 'react-router-dom';

import Thumbnail from '~/components/course/Thumbnail';
import Name from '~/components/shared/Name';
import Description from '~/components/shared/Description';
import Tags from '~/components/shared/Tags';
import { IMG_LOADING_LAZY, PATHS } from '~/lib/constants';
import { useUnitContext } from '~/contexts/Unit';

function CardContent() {
  const navigate = useNavigate();
  const {
    slug,
    name,
    course,
    tags,
  } = useUnitContext();

  const handleNavigate = () => navigate(`${PATHS.COURSE}/${course.slug}${PATHS.UNIT}/${slug}`);

  return (
    <div className="course-card" data-testid="course-card">
      <div
        className="link"
        role="button"
        onClick={handleNavigate}
        onKeyPress={handleNavigate}
        tabIndex={0}
      >
        <Thumbnail
          image={course.thumbnail}
          fallbackAlt={course.name}
          imgLoading={IMG_LOADING_LAZY}
        />
        <Name name={name} />
        <Description description={`Course: ${course.name}`} />
      </div>
      <Tags tags={tags} />
    </div>
  );
}

export default CardContent;

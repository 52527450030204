import PropTypes from 'prop-types';

export interface SearchHeaderProps {
  title: string;
  subtitle: string;
}

function SearchHeader({ title, subtitle }: SearchHeaderProps) {
  return (
    <>
      <h1 className="text-center pt-2 md:pt-8 lg:pt-12 h2 mb-2 md:h1">{title}</h1>
      <p className="text-center mb-6 md:mb-9 lg:mb-10">{subtitle}</p>
    </>
  );
}

SearchHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SearchHeader;

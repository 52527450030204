import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Course } from '~/models/Course';
import CardContent from '~/components/course/CardContent';
import { IMG_LOADING_LAZY, PATHS } from '~/lib/constants';
import CourseContext from '~/contexts/Course';
import { ImgLoadingAttribute } from '~/api/data';

export interface CourseCardProps {
  course: Course;
  disabled?: boolean;
  imgLoading?: ImgLoadingAttribute;
}

function Card({ course, disabled, imgLoading }: CourseCardProps) {
  const navigate = useNavigate();

  const handleNavigate = () => !disabled && navigate(`${PATHS.COURSE}/${course.slug}`);

  return (
    <CourseContext.Provider value={course}>
      <CardContent
        onNavigate={handleNavigate}
        imgLoading={imgLoading}
      />
    </CourseContext.Provider>
  );
}

Card.propTypes = {
  course: PropTypes.instanceOf(Course).isRequired,
  disabled: PropTypes.bool,
  imgLoading: PropTypes.string,
};

Card.defaultProps = {
  disabled: false,
  imgLoading: IMG_LOADING_LAZY,
};

export default Card;

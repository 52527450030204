import PropTypes from 'prop-types';

export interface SlideImageProps {
  smallImage: string;
  mediumImage: string;
  largeImage: string;
  altText: string;
}

function SlideImage({
  smallImage,
  mediumImage,
  largeImage,
  altText,
}: SlideImageProps) {
  return (
    <picture>
      <source
        srcSet={smallImage}
        media="(max-width: 767px)"
      />
      <source
        srcSet={mediumImage}
        media="(min-width: 768px & max-width: 1023px)"
      />
      <source
        srcSet={largeImage}
        media="(min-width: 1024px)"
      />
      <img
        src={largeImage}
        alt={altText}
        width="100%"
      />
    </picture>
  );
}

SlideImage.propTypes = {
  smallImage: PropTypes.string.isRequired,
  mediumImage: PropTypes.string.isRequired,
  largeImage: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default SlideImage;

import { useState, useEffect } from 'react';

import PageLoading from '~/components/layout/PageLoading';
import Hero from '~/components/home/Hero';
import CategoryCarousel from '~/components/carousel/CategoryCarousel';
import { getHomepageData, marketingContent, HomepageData } from '~/api/data';
import { Category } from '~/models/Category';
import { HeroSlide, HeroSlideData } from '~/models/HeroSlide';
import useDocumentTitle from '~/hooks/title';
import { filterSlides } from '~/lib/utils';

function HomePage() {
  const [data, setData] = useState<HomepageData[]>([]);
  const [slides, setSlides] = useState<HeroSlideData[]>([]);

  useDocumentTitle('Home');

  useEffect(() => {
    async function getData() {
      const response = await getHomepageData();
      setData(response);
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const response = await marketingContent();
      setSlides(response);
    }
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && slides.length > 0) {
      const filteredSlides = filterSlides(slides, data);
      setSlides(filteredSlides);
    }
  }, [data]);

  if (!data.length) {
    return <PageLoading />;
  }

  return (
    <div className="homepage-wrapper">
      <Hero slides={slides.map(slide => new HeroSlide(slide))} />
      <div className="page-container">
        <div className="px-6 py-8 lg:px-12" data-testid="homepage-categories">
          {data.map(({
            category,
            slidesToShow,
            breakpoints,
            imgLoading,
          }) => (
            <CategoryCarousel
              key={category.id}
              category={new Category(category)}
              slidesToShow={slidesToShow}
              breakpoints={breakpoints}
              imgLoading={imgLoading}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage;

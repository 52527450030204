export interface HeroSlideData {
  id?: number;
  url?: string;
  alt?: string;
  smallImage?: string;
  mediumImage?: string;
  largeImage?: string;
}

export class HeroSlide {
  id: number;

  url: string;

  altText: string;

  smallImage: string;

  mediumImage: string;

  largeImage: string;

  constructor(data: HeroSlideData = {}) {
    this.id = data.id || 0;
    this.url = data.url || '';
    this.altText = data.alt || '';
    this.smallImage = data.smallImage || '';
    this.mediumImage = data.mediumImage || '';
    this.largeImage = data.largeImage || '';
  }
}

export default HeroSlide;

import PropTypes from 'prop-types';

import { useCourseContext } from '~/contexts/Course';
import { BasicUnit } from '~/models/BasicUnit';
import Select from '~/components/ui/Select';

export interface UnitSelectProps {
  selectedUnit: BasicUnit;
  setSelectedUnit: React.Dispatch<React.SetStateAction<BasicUnit>>;
}

function UnitSelect({ selectedUnit, setSelectedUnit }: UnitSelectProps) {
  const { units } = useCourseContext();

  if (units.length < 2) {
    return null;
  }

  const handleChange = (value: BasicUnit): void => {
    setSelectedUnit(value);
  };

  return (
    <div className="mb-6 md:mb-8">
      <Select
        items={units}
        selectedItem={selectedUnit}
        onChange={handleChange}
        label="Unit"
      />
    </div>
  );
}

UnitSelect.propTypes = {
  selectedUnit: PropTypes.instanceOf(BasicUnit).isRequired,
  setSelectedUnit: PropTypes.func.isRequired,
};

export default UnitSelect;

import PropTypes from 'prop-types';

import { Unit } from '~/models/Unit';
import Card from '~/components/unit/Card';

export interface UnitListProps {
  units: Unit[];
}
function TaggedUnits({ units }: UnitListProps) {
  if (!units.length) {
    return null;
  }

  return (
    <div className="px-6 lg:px-12">
      <h4 className="mb-4">Units</h4>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6">
        {units.map(unit => <Card key={unit.id} unit={unit} />)}
      </div>
    </div>
  );
}

TaggedUnits.propTypes = { units: PropTypes.arrayOf(PropTypes.instanceOf(Unit)).isRequired };

export default TaggedUnits;

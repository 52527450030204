import { Routes, Route } from 'react-router-dom';

import { PATHS } from '~/lib/constants';
import Layout from '~/components/layout';
import RequireAuth from '~/RequireAuth';
import AccountPage from '~/pages/Account';
import CategoryPage from '~/pages/Category';
import CheckoutPage from '~/pages/Checkout';
import CoursePage from '~/pages/Course';
import CreateAccountPage from '~/pages/CreateAccount';
import CreateAccountSuccessPage from '~/pages/CreateAccountSuccess';
import ForgotPage from '~/pages/Forgot';
import HomePage from '~/pages/Home';
import LessonPage from '~/pages/Lesson';
import LoginPage from '~/pages/Login';
import LogoutPage from '~/pages/Logout';
import PageNotFound from '~/pages/NotFound';
import ResetPasswordPage from '~/pages/ResetPassword';
import TagPage from '~/pages/Tag';

function PageRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<Layout />}>
        <Route path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
        <Route path={PATHS.LOGIN} element={<LoginPage />} />
        <Route path={PATHS.LOGIN_EXISTING_ACCOUNT} element={<LoginPage existing />} />
        <Route path={PATHS.LOGIN_PASSWORD_RESET} element={<LoginPage resetSuccess />} />
        <Route path={PATHS.LOGIN_CANCELED_ACCOUNT} element={<LoginPage canceled />} />
        <Route path={PATHS.FORGOT} element={<ForgotPage />} />
        <Route path={PATHS.RESET} element={<ResetPasswordPage />} />
        <Route path={`${PATHS.RESET}/:token`} element={<ResetPasswordPage />} />
        <Route path={PATHS.SIGNUP} element={<CreateAccountPage />} />
        <Route path={PATHS.SIGNUP_SUCCESS} element={<CreateAccountSuccessPage />} />
        <Route path={PATHS.LOGOUT} element={<LogoutPage />} />
        <Route path={`${PATHS.SIGNUP}/:token`} element={<CreateAccountPage />} />
        <Route path={`${PATHS.COURSE}/:courseSlug`} element={<RequireAuth><CoursePage /></RequireAuth>} />
        <Route path={`${PATHS.COURSE}/:courseSlug${PATHS.UNIT}/:unitSlug`} element={<RequireAuth><CoursePage /></RequireAuth>} />
        <Route path={`${PATHS.COURSE}/:courseSlug${PATHS.UNIT}/:unitSlug${PATHS.LESSON}/:lessonSlug`} element={<RequireAuth><LessonPage /></RequireAuth>} />
        <Route path={`${PATHS.TAG}/:slug`} element={<RequireAuth><TagPage /></RequireAuth>} />
        <Route path={`${PATHS.CATEGORY}/:slug`} element={<RequireAuth><CategoryPage /></RequireAuth>} />
        <Route path={PATHS.ACCOUNT} element={<RequireAuth><AccountPage /></RequireAuth>} />
        <Route path={PATHS.GROW_CHECKOUT} element={<RequireAuth><CheckoutPage heroVideoType="grow" /></RequireAuth>} />
        <Route path={PATHS.LAUNCH_CHECKOUT} element={<RequireAuth><CheckoutPage heroVideoType="launch" /></RequireAuth>} />
      </Route>
    </Routes>
  );
}

export default PageRoutes;

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import { Check, ChevronDown } from 'react-feather';

import Label from '~/components/ui/Label';
import { classNames } from '~/components/ui/utils';

export interface UiSelectItem {
  id: number | string;
  name: string;
}

export interface UiSelectProps {
  items: UiSelectItem[];
  selectedItem: UiSelectItem;
  onChange: (value: any) => void;
  label?: string;
}

function Select({
  items,
  selectedItem,
  onChange,
  label,
}: UiSelectProps) {
  return (
    <>
      <Label label={label} />
      <Listbox value={selectedItem} onChange={onChange}>
        {({ open }) => (
          <div className="relative pt-2">
            <Listbox.Button className={classNames('ui-select-input', open && 'ui-select-input-open')}>
              <span className="ui-select-input-label">{selectedItem.name}</span>
              <span className="ui-select-input-arrow">
                <ChevronDown className="ui-select-input-arrow-icon" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="ui-select-options"
              >
                {items.map(item => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) => classNames('ui-select-option', active && 'ui-select-option-active')}
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span className={classNames('ui-select-option-label', selected && 'ui-select-option-label-selected')}>
                          {item.name}
                        </span>
                        {selected && (
                          <span className="ui-select-option-check">
                            <Check className="ui-select-option-check-icon" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </>
  );
}

const SelectItemShape = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
});

Select.propTypes = {
  items: PropTypes.arrayOf(SelectItemShape).isRequired,
  selectedItem: SelectItemShape.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

Select.defaultProps = { label: '' };

export default Select;

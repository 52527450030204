function LaunchFeatureList() {
  return (
    <>
      <h2 className="checkout-card-feature-list-header mt-6">All standard features, including:</h2>
      <ul className="checkout-card-feature-list-wrapper">
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">1 admin users</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Standard</span>
          <span>integrations</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Standard</span>
          <span>email support</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span>Flexible customer billing options</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span>Dashboard and report sales</span>
        </li>
      </ul>
    </>
  );
}

export default LaunchFeatureList;

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Tag as TagModel } from '~/models/Tag';
import classNames from '~/components/ui/utils';
import { PATHS } from '~/lib/constants';

export interface TagProps {
  tag: TagModel;
}

function Tag({ tag }: TagProps) {
  return (
    <li className="tag">
      <Link onClick={e => e.stopPropagation()} to={`${PATHS.TAG}/${tag.slug}`} className={classNames('tag-link', tag.color)}>
        {tag.name}
      </Link>
    </li>
  );
}

Tag.propTypes = { tag: PropTypes.instanceOf(TagModel).isRequired };

export default Tag;

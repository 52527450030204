import { ImageUpload, ImageUploadData } from '~/models/ImageUpload';

export interface BasicCourseData {
  id?: string;
  slug?: string;
  name?: string;
  description?: string;
  content?: string;
  thumbnail?: ImageUploadData;
}

export class BasicCourse {
  id: string;

  slug: string;

  name: string;

  description: string;

  content?: string;

  thumbnail: ImageUpload;

  constructor(data: BasicCourseData = {}) {
    this.id = data.id || '';
    this.slug = data.slug || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.content = data.content || '';
    this.thumbnail = new ImageUpload(data.thumbnail);
  }
}

export default BasicCourse;

import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';
import { useState, ReactNode } from 'react';
import { AlertTriangle } from 'react-feather';
import { DateTime } from 'luxon';

import Modal from '~/components/shared/Modal';
import Button from '~/components/ui/Button';
import { cancelAccount } from '~/api/account';
import { useAuth } from '~/contexts/Auth';
import { ScheduledCancel } from '~/components/account/AlertMessages';
import { ErrorData } from '~/types/data';
import { AccountData } from '~/models/Account';
import { AlertClassName } from '~/components/account/Alert';

export enum StepsEnum {
  chooseAction = 'chooseAction',
  cancelAccountAction = 'cancelAccountAction',
}

export type Steps = `${StepsEnum}`
export interface Step {
  body: JSX.Element,
  title?: string,
  acceptButton: JSX.Element,
  rejectButton: JSX.Element,
}

export interface StepPicker {
  [k: string]: Step
}

export interface ManageAccountModalProps {
  open: boolean;
  handleClose(): void;
  setAccount(account: AccountData): void;
  showAlert(className: AlertClassName, message: ReactNode): void;
  handleOpenReactivateModal(): void;
}

function ChooseActionBody({ cancelStep }: { cancelStep(): void }) {
  return (
    <div className="py-5 mx-6 px-6 my-16 h-32 shadow-dark rounded flex items-start justify-between">
      <div>
        <h2 className="modal-option-title">Cancel My Subscription</h2>
        <p className="text-md text-white">
          You will lose access to your subscription at the end of the current
          billing period.
        </p>
      </div>
      <div className="w-40 h-8">
        <Button
          label="Cancel subscription"
          type="button"
          variant="outlined"
          color="warning"
          size="large"
          onClick={cancelStep}
        />
      </div>
    </div>
  );
}

function CancelBody() {
  return (
    <div className="h-72 px-16 text-center mx-auto">
      <AlertTriangle size="100" className="mx-auto my-8 text-red-400" />
      <h4 className="text-white mb-6">
        Cancel My Subscription
      </h4>
      <p className="text-white">
        You&apos;ll lose access to this subscription at the end of your current billing period.
        The good news is that we&apos;ll hold onto your account data so you can reactivate at any time.
      </p>
    </div>
  );
}

function ManageAccountModal({
  open,
  handleClose,
  setAccount,
  showAlert,
  handleOpenReactivateModal,
}: ManageAccountModalProps) {
  const [step, setStep] = useState<Steps>('chooseAction');
  const [disabled, setDisabled] = useState(false);
  const auth = useAuth();

  const resetModal = () => {
    handleClose();
    setTimeout(() => {
      setDisabled(false);
      setStep('chooseAction');
    }, 250);
  };

  const handleCancel = async () => {
    try {
      setDisabled(true);
      const data = await cancelAccount(auth.user.uuid);
      setAccount(data);
      const { scheduledCancelDate = '' } = data;
      const formattedCancelDate = DateTime.fromSQL(scheduledCancelDate).toFormat('DDD');
      showAlert('warning', <ScheduledCancel cancelDate={formattedCancelDate} onClick={handleOpenReactivateModal} />);
    } catch (err) {
      const error = err as AxiosError<ErrorData>;
      Bugsnag.notify(error, (event) => {
        event.addMetadata('userUuidDetails', { uuid: auth.user.uuid });
      });
      showAlert('warning', 'Unable to cancel subscription.');
    } finally {
      resetModal();
    }
  };
  const steps: StepPicker = {
    chooseAction: {
      title: 'Manage Subscription',
      body: <ChooseActionBody
        cancelStep={() => setStep('cancelAccountAction')}
      />,
      acceptButton: <Button
        label="I'll Keep My Subscription"
        type="button"
        variant="primary"
        color="success"
        size="large"
        onClick={resetModal}
      />,
      rejectButton: <Button
        label="Close"
        type="button"
        variant="outlined"
        color="white"
        size="large"
        onClick={resetModal}
      />,
    },
    cancelAccountAction: {
      body: <CancelBody />,
      acceptButton: <Button
        label="Confirm and Cancel"
        type="button"
        disabled={disabled}
        variant="primary"
        color="warning"
        size="large"
        onClick={handleCancel}
      />,
      rejectButton: <Button
        label="Never Mind"
        type="button"
        disabled={disabled}
        variant="outlined"
        color="white"
        size="large"
        onClick={() => setStep('chooseAction')}
      />,
    },
  };
  const {
    body,
    acceptButton,
    rejectButton,
    title,
  } = steps[step];

  return (
    <Modal
      open={open}
      handleClose={resetModal}
      title={title}
      body={body}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
    />
  );
}

export default ManageAccountModal;

import { useCourseContext } from '~/contexts/Course';

function CourseProgress() {
  const { progress } = useCourseContext();

  if (progress === 0) {
    return null;
  }

  return (
    <p className="font-medium mt-4 text-center md:mt-0 md:text-left md:mr-10">
      {progress}
      % complete
    </p>
  );
}

export default CourseProgress;

import { HeroSlideData } from '~/models/HeroSlide';
import { HomepageData } from '~/api/data';
import { Unit } from '~/models/Unit';

export function validEmailAddress(email?: string | null): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email));
}

export function hashCode(str: string): number {
  // eslint-disable-next-line no-bitwise
  return Math.abs(str.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0));
}

export function unitsWithLessons(units: Unit[]): Unit[] {
  return units.filter(({ lessons }) => lessons.length);
}

export function formatPrice(price: number, decimalSpaces = 2) {
  return (price / 100).toFixed(decimalSpaces);
}

export function formatPriceWithCurrency(price: number, decimalSpaces = 2, currencySymbol = '$') {
  return `${currencySymbol}${formatPrice(price, decimalSpaces)}`;
}

export function getCourseIdsFromCategories(categories: HomepageData[]) {
  return categories.reduce<string[]>((acc: string[], item: HomepageData) => {
    const courses = item?.category?.courses || [];
    const ids = courses.map(({ id }) => id || '');
    return [...acc, ...ids];
  }, []);
}

export function getCourseIdFromUrl(url: string) {
  return url.split('/')[2];
}

export function filterSlides(slides: HeroSlideData[], categories: HomepageData[]) {
  // get all course ids
  const courseIds = getCourseIdsFromCategories(categories);
  // filter out slides that do not have urls in the course ids
  return slides.filter((slide) => {
    const uuid = getCourseIdFromUrl(slide.url || '');
    return courseIds.includes(uuid);
  });
}

export default validEmailAddress;

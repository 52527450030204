import PropTypes from 'prop-types';

export interface UiDescriptionProps {
  description?: string;
}

function Description({ description }: UiDescriptionProps) {
  if (!description) {
    return null;
  }
  return (
    <p className="ui-description">
      {description}
    </p>
  );
}

Description.propTypes = { description: PropTypes.string };

Description.defaultProps = { description: null };

export default Description;

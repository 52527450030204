import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';

import { API_PATHS } from '~/lib/constants';
import { getRequest, postRequest } from '~/api';
import { ErrorData } from '~/types/data';

export interface CreatedDate {
  date?: string;
}
export interface UserData {
  id?: string;
  userUuid?: string;
  mkOwnerUuid?: string;
  billingPageUrl?: string;
  userCreatedAt?: CreatedDate;
  firstName?: string;
  lastName?: string;
  email?: string;
  token?: string;
  existing?: boolean;
  launchDarklyHash?: string;
}

export const authProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    authProvider.isAuthenticated = true;
    setTimeout(callback, 100);
  },
  signout(callback: VoidFunction) {
    authProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export async function validateSignupToken(cartToken: string): Promise<UserData> {
  try {
    const { data } = await getRequest(`${API_PATHS.VALIDATE_SIGNUP}/${cartToken}`);
    return {
      ...data.customer,
      existing: data.existing,
    };
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('signupTokenDetails', { cartToken });
    });
    return Promise.reject(error.response?.data.error || 'Unable to locate order.');
  }
}

export async function createAccount(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  cartToken: string,
): Promise<UserData> {
  try {
    const { data } = await postRequest(API_PATHS.SIGNUP, {
      firstName,
      lastName,
      email,
      password,
      cartToken,
    });
    return data;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('createAccountDetails', {
        firstName,
        lastName,
        email,
        cartToken,
      });
    });
    return Promise.reject(error.response?.data.error || 'Unable to complete the request at this time.');
  }
}

export async function validateLogin(email: string, password: string): Promise<UserData> {
  try {
    const { data } = await postRequest(API_PATHS.LOGIN, { email, password });
    if (!data.token || data.token === 'false') {
      Bugsnag.notify(new Error('Token not found in login response'), (event) => {
        const cartToken = localStorage.getItem('cart');
        event.addMetadata('loginDetails', { ...data, email, cartToken });
      });
      const message = 'Unable to login at this time.';
      return Promise.reject(message);
    }
    return data;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    const message = error?.response?.data?.error || 'Unable to login.';
    if (error?.response?.status !== 400) {
      Bugsnag.notify(error, (event) => {
        const cartToken = localStorage.getItem('cart');
        event.addMetadata('loginDetails', { email, cartToken });
      });
    }
    return Promise.reject(message);
  }
}

export async function enrollUser(cartToken: string, firstName: string, lastName: string): Promise<boolean> {
  try {
    await postRequest(API_PATHS.ENROLL, { cart: cartToken, firstName, lastName });
    return true;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('enrollUserDetails', { cartToken, firstName, lastName });
    });
    return Promise.reject(error.response?.data.error || 'Unable to enroll account.');
  }
}

export async function forgotPassword(email: string): Promise<boolean> {
  try {
    await postRequest(API_PATHS.FORGOT_PASSWORD, { email });
  } catch (err) {
    const error = err as AxiosError;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('forgotPasswordDetails', { email });
    });
  }
  return true;
}

export async function resetPassword(
  password: string,
  confirmPassword: string,
  token: string,
): Promise<boolean> {
  try {
    const { data } = await postRequest(API_PATHS.RESET_PASSWORD, {
      password,
      code: token,
      password_confirm: confirmPassword,
    });
    return data;
  } catch (err) {
    const error = err as AxiosError<ErrorData>;
    Bugsnag.notify(error, (event) => {
      event.addMetadata('resetPasswordDetails', { token });
    });
    return Promise.reject(error.response?.data.error || 'Unable to reset password.');
  }
}

export async function logoutUser(): Promise<boolean> {
  try {
    await postRequest(API_PATHS.LOGOUT);
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.status !== 401) {
      Bugsnag.notify(error);
    }
  }
  return true;
}

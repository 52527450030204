import { useNavigate } from 'react-router-dom';

import Button from '~/components/ui/Button';
import useDocumentTitle from '~/hooks/title';

function CreateAccountSuccessPage() {
  const navigate = useNavigate();
  const course = localStorage.getItem('courseRedirect');
  const redirect = course
    ? `/course/${course}`
    : '/';
  localStorage.removeItem('courseRedirect');
  useDocumentTitle('Create account success');

  return (
    <>
      <h3 className="mb-2">Account created!</h3>
      <p className="mb-8">Your CreatorU account is now active.</p>
      <Button
        label="Start learning"
        onClick={() => navigate(redirect)}
      />
    </>
  );
}

export default CreateAccountSuccessPage;

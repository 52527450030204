import { useNavigate } from 'react-router-dom';
import { Play } from 'react-feather';

import Button from '~/components/ui/Button';
import { PATHS } from '~/lib/constants';
import { useCourseContext } from '~/contexts/Course';

function StartButton() {
  const {
    slug,
    lessonCount,
    units,
    nextLesson,
  } = useCourseContext();

  let label: string;
  let unitSlug: string;
  let lessonSlug: string;

  if (nextLesson.id === units[0]?.lessons[0]?.id) {
    label = 'Play all lessons';
    unitSlug = units[0]?.slug || '';
    lessonSlug = units[0]?.lessons[0]?.slug || '';
  } else {
    label = 'Continue watching';
    unitSlug = nextLesson.unit.slug || '';
    lessonSlug = nextLesson.slug || '';
  }

  if (!lessonCount || !unitSlug.length || !lessonSlug.length) {
    return null;
  }

  const navigate = useNavigate();
  const handleClick = () => navigate(`${PATHS.COURSE}/${slug}${PATHS.UNIT}/${unitSlug}${PATHS.LESSON}/${lessonSlug}`);

  return (
    <div className="md:w-44">
      <Button
        name="start"
        label={label}
        type="button"
        variant="secondary"
        leadingIcon={<Play />}
        onClick={handleClick}
      />
    </div>
  );
}

export default StartButton;

import Lesson from '~/components/lesson/Lesson';
import { useUnitContext } from '~/contexts/Unit';

function UnitLessons() {
  const { lessons } = useUnitContext();

  return (
    <div className="unit-lessons">
      {lessons.map(lesson => <Lesson key={lesson.id} lesson={lesson} />)}
    </div>
  );
}

export default UnitLessons;

import PropTypes from 'prop-types';
import { X } from 'react-feather';

export interface ClearSearchButtonProps {
  onClick(): void;
}

function ClearSearchButton({ onClick }: ClearSearchButtonProps) {
  return (
    <button
      className="nav-clear-button nav-button"
      onClick={onClick}
      aria-label="Clear search value"
    >
      <X />
    </button>
  );
}

ClearSearchButton.propTypes = { onClick: PropTypes.func.isRequired };

export default ClearSearchButton;

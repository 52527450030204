function LoginHeaderPasswordReset() {
  return (
    <>
      <h3 className="mb-2">Password updated</h3>
      <p>Use your new password to log in.</p>
    </>
  );
}

export default LoginHeaderPasswordReset;

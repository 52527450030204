import { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '~/components/ui/Input';

export interface PasswordFieldsProps {
  passwordLabel: string;
  password: string;
  handleChangePassword(event: React.ChangeEvent<HTMLInputElement>): void;
  confirmPasswordLabel: string;
  confirmPassword: string;
  handleChangeConfirmPassword(event: React.ChangeEvent<HTMLInputElement>): void;
  requirementError: boolean;
  setRequirementError: React.Dispatch<React.SetStateAction<boolean>>;
  pristine: boolean;
  error: string;
  processing: boolean;
}

function PasswordFields({
  passwordLabel,
  password,
  handleChangePassword,
  confirmPasswordLabel,
  confirmPassword,
  handleChangeConfirmPassword,
  requirementError,
  setRequirementError,
  pristine,
  error,
  processing,
}: PasswordFieldsProps) {
  const [mismatch, setMismatch] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequirementError(false);
    handleChangePassword(event);
  };

  const handleBlurPassword = () => {
    if (confirmPassword.length) {
      setMismatch(password !== confirmPassword);
    }
    if (password.length) {
      setRequirementError(password.length < 8);
    } else {
      setRequirementError(false);
    }
  };

  const handleBlurConfirmPassword = () => {
    if (confirmPassword.length) {
      setMismatch(password !== confirmPassword);
    } else {
      setMismatch(false);
    }
  };

  return (
    <>
      <div className="pt-6">
        <Input
          id="password"
          label={passwordLabel}
          name="password"
          type="password"
          disabled={processing}
          value={password}
          onChange={handleChange}
          error={(pristine && !!error.length) || requirementError}
          onBlur={handleBlurPassword}
          errorMessage={requirementError ? 'Password must be at least 8 characters in length' : ''}
        />
      </div>

      <div className="pt-6">
        <Input
          id="confirmPassword"
          label={confirmPasswordLabel}
          name="confirmPassword"
          type="password"
          disabled={processing}
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          error={(pristine && !!error.length) || mismatch}
          onBlur={handleBlurConfirmPassword}
          onFocus={() => setMismatch(false)}
          errorMessage={mismatch ? 'Password does not match' : ''}
        />
      </div>
    </>
  );
}

PasswordFields.propTypes = {
  passwordLabel: PropTypes.string,
  password: PropTypes.string.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  confirmPasswordLabel: PropTypes.string,
  confirmPassword: PropTypes.string.isRequired,
  handleChangeConfirmPassword: PropTypes.func.isRequired,
  requirementError: PropTypes.bool.isRequired,
  setRequirementError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
};

PasswordFields.defaultProps = {
  passwordLabel: 'Password',
  confirmPasswordLabel: 'Confirm password',
};

export default PasswordFields;

import PropTypes from 'prop-types';

import { Tag as TagModel } from '~/models/Tag';
import Tag from '~/components/shared/Tag';

export interface TagsProps {
  tags: TagModel[];
}

function Tags({ tags }: TagsProps) {
  if (!tags.length) {
    return null;
  }

  return (
    <ul className="tags">
      {tags.map(tag => <Tag key={tag.id} tag={tag} />)}
    </ul>
  );
}

Tags.propTypes = { tags: PropTypes.arrayOf(PropTypes.instanceOf(TagModel)).isRequired };

export default Tags;

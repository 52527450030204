import VideoPlayer from '~/components/shared/VideoPlayer';
import VideoChat from '~/components/lesson/VideoChat';
import { useLessonContext } from '~/contexts/Lesson';

function LessonVideo() {
  const lesson = useLessonContext();
  const {
    video,
    chatEmbedUrl,
  } = lesson;
  return video.url ? (
    <div className="lesson-video">
      <VideoPlayer video={video} />
      {chatEmbedUrl && (
        <VideoChat chatEmbedUrl={chatEmbedUrl} />
      )}
    </div>
  ) : null;
}

export default LessonVideo;

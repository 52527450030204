import Name from '~/components/shared/Name';
import Downloads from '~/components/lesson/Downloads';
import NextButton from '~/components/lesson/NextButton';
import Content from '~/components/shared/Content';
import { useLessonContext } from '~/contexts/Lesson';

function LessonDetails() {
  const lesson = useLessonContext();
  const { name, content } = lesson;
  return (
    <>
      <Name name={name} />
      <Content content={content} />
      <div className="pt-4 md:pt-6 lg:grid lg:grid-cols-10">
        <div className="mb-4 w-full lg:mb-0 lg:col-span-7">
          <Downloads />
        </div>
        <div className="lg:col-span-3 md:flex md:justify-end md:items-end">
          <NextButton />
        </div>
      </div>
    </>
  );
}

export default LessonDetails;

import americanExpress from '~/graphics/card-icons/american-express.png';
import dinersClub from '~/graphics/card-icons/diners-club.png';
import discover from '~/graphics/card-icons/discover.png';
import jcb from '~/graphics/card-icons/jcb.png';
import mastercard from '~/graphics/card-icons/mastercard.png';
import visa from '~/graphics/card-icons/visa.png';
import unknown from '~/graphics/card-icons/unknown.png';
import { CreditCardBrands } from '~/models/Account';

export interface CreditCardProps {
  last4?: string;
  brand?: CreditCardBrands;
  expirationDate?: string;
}

function CreditCard({
  last4,
  brand,
  expirationDate,
}: CreditCardProps) {
  let brandImage;
  let brandText;
  switch (brand) {
    case 'visa':
      brandImage = visa;
      brandText = 'Visa';
      break;
    case 'mastercard':
      brandImage = mastercard;
      brandText = 'Mastercard';
      break;
    case 'jcb':
      brandImage = jcb;
      brandText = 'JCB';
      break;
    case 'american_express':
      brandImage = americanExpress;
      brandText = 'American Express';
      break;
    case 'discover':
      brandImage = discover;
      brandText = 'Discover';
      break;
    case 'diners_club':
      brandImage = dinersClub;
      brandText = "Diner's Club";
      break;
    default:
      brandImage = unknown;
      brandText = 'Credit Card';
      break;
  }
  return (
    <>
      <div className="flex flex-row gap-4 mt-4 items-center">
        <img className="lg:w-16 md:w-12" src={brandImage} alt={brandText} />
        <p className="capitalize text-xl">
          {brandText}
          &nbsp;••••&nbsp;
          {last4}
        </p>
      </div>
      <p className="text-md mt-2 text-dark">
        Expires
        {' '}
        {expirationDate}
      </p>
    </>
  );
}

export default CreditCard;

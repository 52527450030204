import { useEffect, useState, useRef } from 'react';

import { getAlgoliaSearchData, getKey } from '~/api/algolia';
import { getSearchData as getCoursesSearchData, SearchType } from '~/api/data';
import useFeatureFlags from '~/hooks/feature-flags';

function useSearch(value: string) {
  const [searchData, setSearchData] = useState<SearchType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [algoliaKey, setAlgoliaKey] = useState<string>('');
  const cache = useRef<SearchType[]>([]);
  const { hasBackendSearch } = useFeatureFlags();
  let getSearchData = async () => {
    let response = cache.current;
    if (!response.length && !loading) {
      setLoading(true);
      response = await getCoursesSearchData();
      cache.current = response;
      setLoading(false);
    }

    return cache.current.map(({ type, data }) => {
      const filteredData = data.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
      return {
        type,
        data: filteredData,
      };
    });
  };

  useEffect(() => {
    async function getAlgoliaSearchKey() {
      const key = await getKey();
      setAlgoliaKey(key);
    }

    if (hasBackendSearch) {
      getAlgoliaSearchKey();
    }
  }, [hasBackendSearch]);

  if (hasBackendSearch) {
    getSearchData = async () => {
      if (!algoliaKey) {
        return [];
      }

      return getAlgoliaSearchData(algoliaKey, value);
    };
  }

  useEffect(() => {
    async function getData() {
      let response: SearchType[] = [];
      if (value) {
        response = await getSearchData();
      }

      setSearchData(response);
    }
    getData();
  }, [value, algoliaKey]);

  return { data: searchData, loading };
}

export default useSearch;

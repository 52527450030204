import { ReactNode } from 'react';
import {
  bool,
  func,
  instanceOf,
  string,
} from 'prop-types';
import { X } from 'react-feather';

export enum AlertClassNameEnum {
  primary = 'primary',
  warning = 'warning',
  success = 'success',
}

export type AlertClassName = `${AlertClassNameEnum}`;

export interface AlertProps {
  className: AlertClassName;
  icon: ReactNode;
  open: boolean;
  canClose: boolean;
  handleClose(): void;
  message: ReactNode;
}

function Alert({
  className,
  icon,
  open,
  handleClose,
  canClose,
  message,
}: AlertProps) {
  return (
    <div className="ui-alert">
      {open && (
        <div className={`flex flex-row items-center w-full h-min-12 text-white rounded ${className}`}>
          <div className="self-center p-3">
            {icon}
          </div>
          <div className="text-white md:text-left sm:text-center grow">
            <p>
              {message}
            </p>
          </div>
          {canClose && (
            <button
              className="mr-4"
              onClick={handleClose}
              aria-label="Close alert"
            >
              <X />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
Alert.propTypes = {
  className: string.isRequired,
  icon: instanceOf(Object).isRequired,
  open: bool.isRequired,
  handleClose: func,
  canClose: bool,
  message: instanceOf(Object).isRequired,
};
Alert.defaultProps = { canClose: false, handleClose: () => false };
export default Alert;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import VideoPlayer from '~/components/shared/VideoPlayer';
import { Video } from '~/models/Video';
import { TERMS_LINK } from '~/lib/constants';
import launchIcon from '~/graphics/samcart-products/launch-icon.svg';
import growIcon from '~/graphics/samcart-products/grow-icon.svg';
import scaleIcon from '~/graphics/samcart-products/scale-icon.svg';
import Input from '~/components/ui/Input';
import ButtonGroup from '~/components/ui/ButtonGroup';
import Button from '~/components/ui/Button';
import Card, { CardParameters } from '~/components/checkout/Card';
import CreditCard from '~/components/account/CreditCard';
import { useAccount } from '~/contexts/Account';
import useDocumentTitle from '~/hooks/title';
import PayPal from '~/components/account/PayPal';
import { useAuth } from '~/contexts/Auth';
import { getSamCartTieredProducts, SamCartTieredProductData, getSamCartOnboardingData } from '~/api/data';
import purchaseWithExistingCard from '~/api/payment';
import PurchaseComplete from '~/components/checkout/PurchaseComplete';
import PurchaseFailed from '~/components/checkout/PurchaseFailed';
import LaunchFeatureList from '~/components/checkout/LaunchFeatureList';
import GrowFeatureList from '~/components/checkout/GrowFeatureList';
import ScaleFeatureList from '~/components/checkout/ScaleFeatureList';

export interface CheckoutPageProps {
  heroVideoType?: string;
}

export type PlanFrequency = 'monthly' | 'yearly';
export type PlanTier = 'grow' | 'scale' | 'launch';

function CheckoutPage({ heroVideoType }: CheckoutPageProps) {
  useDocumentTitle('Sign Up for SamCart!');
  const navigate = useNavigate();
  const { account: accountData } = useAccount();
  const account = useMemo(() => accountData, [accountData]);
  const auth = useAuth();
  const [tier, setTier] = useState<PlanTier>('grow');
  const [frequency, setFrequency] = useState<PlanFrequency>('monthly');
  const [plans, setPlans] = useState<SamCartTieredProductData[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseFailed, setPurchaseFailed] = useState(false);
  const videoUrl = heroVideoType === 'launch' ? 'https://brianmoran01.wistia.com/medias/x1yo7bcxw6' : 'https://brianmoran01.wistia.com/medias/8c66p9xr3p';

  useEffect(() => {
    getSamCartOnboardingData(auth.user.uuid).then((res) => {
      if (res.isSamCartCustomer) {
        if (res.samCartBillingPageUrl === '' || res.link) {
          navigate('/');
        } else {
          window.location.href = res.samCartBillingPageUrl || '';
        }
      }
    });
  });

  useEffect(() => {
    async function getProductData() {
      const response = await getSamCartTieredProducts('tiered');

      const launchPlan = response?.find(x => x.tierName === 'launch');
      const growPlan = response?.find(x => x.tierName === 'grow');
      const scalePlan = response?.find(x => x.tierName === 'scale');

      const result: SamCartTieredProductData[] = [];

      if (launchPlan) {
        result.push({
          ...launchPlan,
          productImage: launchIcon,
          featureList: <LaunchFeatureList />,
        });
      }
      if (growPlan) {
        result.push({
          ...growPlan,
          productImage: growIcon,
          featureList: <GrowFeatureList />,
        });
      }
      if (scalePlan) {
        result.push({
          ...scalePlan,
          productImage: scaleIcon,
          featureList: <ScaleFeatureList />,
        });
      }
      setPlans(result);
    }

    getProductData();
  }, []);

  const handleCardClick = (newTier: PlanTier): void => {
    setTier(newTier);
  };

  const handleSelected = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    setFrequency(target.id as PlanFrequency);
  };

  const PlanFrequency = [
    {
      id: 'monthly',
      label: 'Monthly',
      name: 'monthly',
      selected: frequency === 'monthly',
    },
    {
      id: 'yearly',
      label: 'Annual',
      name: 'annual',
      selected: frequency === 'yearly',
    },
  ];

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    const productPlan = plans.filter(x => x.tierName === tier)[0];
    if (!productPlan) {
      return;
    }
    const productId = frequency === 'monthly' ? productPlan.monthlyProductId : productPlan.yearlyProductId;
    const userId = auth.user.uuid;
    setDisableSubmit(true);
    purchaseWithExistingCard(productId, userId)
      .then((response) => {
        if (response === '') {
          setPurchaseFailed(true);
        }
        setLoading(true);
        setTimeout(() => {
          window.location.href = response;
        }, 2000);
      })
      .catch(() => {
        setPurchaseFailed(true);
      });
  };

  function createCardParameters(plan: SamCartTieredProductData): CardParameters {
    return {
      id: plan.id,
      tier: plan.tierName,
      frequency: plan.frequency,
      productImage: plan.productImage,
      monthlyPrice: plan.recurringMonthlyPrice,
      monthlyBreakdownOfAnnualPrice: plan.recurringYearlyPrice / 12,
      annualPrice: plan.recurringYearlyPrice,
      discount: plan.discount,
      featureList: plan.featureList,
    } as CardParameters;
  }

  function showPurchaseFailed() {
    return !loading && purchaseFailed;
  }

  function showCheckoutPage() {
    return !loading && !purchaseFailed;
  }

  function showPurchaseComplete() {
    return loading;
  }

  return (
    <>
      {showPurchaseComplete() && <PurchaseComplete />}
      {showPurchaseFailed() && <PurchaseFailed />}
      {showCheckoutPage() && (
        <div className="min-h-screen pt-14 xl:pt-16">
          <div className="px-6 pt-14 mx-auto max-w-screen-lg">
            <h1 className="mb-5 font-display font-medium tracking-[-1px] xs:text-2xl md:pl-4 lg:px-12">
              Sign Up for SamCart!
            </h1>
            <div className="xs:p-0 mt-4 md:px-4 lg:px-12">
              <VideoPlayer video={new Video({ url: videoUrl })} />
            </div>
            <div className="mt-6 checkout-plan-container">
              <ButtonGroup
                options={PlanFrequency}
                onChange={handleSelected}
              />
              <p className="dark:text-white text-center sm:my-4 xs:px-4">
                Choose the plan that’s right for you and start your 7-day trial today.
              </p>

              <div className="grid max-w-md grid-cols-1 gap-6 mx-auto auto-rows-fr md:grid-cols-3 sm:max-w-full px-4">
                {plans
                  .map(plan => createCardParameters(plan))
                  .map(card => (
                    <Card
                      key={card.id}
                      parameters={card}
                      selectedTier={tier}
                      frequency={frequency}
                      onClick={handleCardClick}
                    />
                  ))}
              </div>
              <div className="checkout-form mb-6">
                <form aria-label="Checkout form" className="px-4 md:px-8">
                  <div className="checkout-billing-information">
                    <Input
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      type="text"
                      value={auth.user.firstName ? auth.user.firstName : ''}
                      disabled={!!auth.user.firstName}
                    />

                    <Input
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      type="text"
                      value={auth.user.lastName ? auth.user.lastName : ''}
                      disabled={!!auth.user.lastName}
                    />

                    <Input
                      id="email"
                      label="Email"
                      name="email"
                      type="text"
                      value={auth.user.email ? auth.user.email : ''}
                      disabled={!!auth.user.email}
                    />

                    <Input
                      id="phone"
                      label="Phone"
                      name="phone"
                      type="text"
                    />
                  </div>
                  <hr className="border-gray-800" />
                  <div className="mb-10">
                    <div className="mt-4 mb-16">
                      <p className="text-medium">Pay With</p>
                      <div className="flex flex-1 flex-col">
                        <div className="flex flex-row items-center mb-4">
                          <label htmlFor="existing-payment-method">
                            {account.paymentSourceType === 'paypal'
                              ? <PayPal />
                              : (
                                <CreditCard
                                  brand={account.card.brand}
                                  last4={account.card.last4}
                                  expirationDate={account.card.expirationDate}
                                />
                              )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto max-w-xl">
                      <Button
                        label="Get Started FREE"
                        type="button"
                        disabled={disableSubmit}
                        variant="primary"
                        color="success"
                        size="large"
                        onClick={handleSubmit}
                      />
                    </div>
                    <p className="text-center my-4">
                      By placing this order you are agreeing to the
                      <a href={TERMS_LINK} target="blank"> Terms & Conditions.</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CheckoutPage;

import PropTypes from 'prop-types';

export interface UiButtonGroupItem {
  id: string;
  name: string;
  label: string;
  selected?: boolean;
}

export interface UiButtonGroupProps {
  options?: UiButtonGroupItem[];
  onChange: React.MouseEventHandler<HTMLButtonElement>;
}

function ButtonGroup({ options, onChange } : UiButtonGroupProps) {
  return (
    <div className="ui-button-group" role="group">
      {options?.map(({
        id, label, name, selected,
      }) => (
        <button
          key={id}
          id={id}
          name={name}
          type="button"
          className={`${selected ? 'ring-2 dark:text-black dark:bg-white ring-gray-500' : 'dark:text-white'}`}
          onClick={onChange}
        >
          {label}
        </button>
      ))}
    </div>
  );
}

const ButtonGroupItemShape = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
});

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(ButtonGroupItemShape).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ButtonGroup;

import PropTypes from 'prop-types';

export interface BannerContentProps {
  label: string;
  image: string;
  imageAltText: string;
  imageClassName: string;
}

function BannerContent({
  label,
  image,
  imageAltText,
  imageClassName,
} : BannerContentProps) {
  return (
    <div className="mt-[54px] bg-white py-3 xl:mt-16 flex justify-end">
      <img src={image} alt={imageAltText} className={imageClassName} />
      <span className="pr-6 pl-2 text-blue-700 text-sm font-display font-extrabold uppercase tracking-[1px]">
        {label}
      </span>
    </div>
  );
}

BannerContent.propTypes = {
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  imageClassName: PropTypes.string.isRequired,
};

export default BannerContent;

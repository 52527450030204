import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { PATHS } from '~/lib/constants';
import { useAuth } from '~/contexts/Auth';
import { useAccount } from '~/contexts/Account';
import initializePendo from '~/lib/pendo';
import useFeatureFlags from '~/hooks/feature-flags';

export default function RequireActiveSubscription({ children }: { children: JSX.Element }) {
  const { hasAccountPage } = useFeatureFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { account } = useAccount();

  useEffect(() => {
    initializePendo(auth.user, account);
  }, [auth, account]);

  useEffect(() => {
    // If the user's account is not active, redirect all authenticated pages to the account page
    if (!account.isActive && location.pathname !== PATHS.ACCOUNT && hasAccountPage) {
      navigate(PATHS.ACCOUNT, { state: { from: location }, replace: true });
    }
  }, [account, location, navigate, hasAccountPage]);

  return children;
}

export default function LessonChat({ chatEmbedUrl }: { chatEmbedUrl: string }) {
  return (
    <div className="live-chat">
      <iframe
        className="video-live-chat"
        title="chat"
        src={chatEmbedUrl}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}

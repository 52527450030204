import PropTypes from 'prop-types';

import { Course } from '~/models/Course';
import Row from '~/components/grid/Row';
import { IMG_LOADING_EAGER, IMG_LOADING_LAZY } from '~/lib/constants';
import { CourseListProps } from '~/types/components';

function Grid({ courses }: CourseListProps) {
  return (
    <>
      <Row
        className="px-6 grid grid-cols-1 md:grid-cols-2 md:gap-4"
        courses={courses.slice(0, 2)}
        imgLoading={IMG_LOADING_EAGER}
      />
      <Row
        className="px-6 grid grid-cols-1 md:grid-cols-3 md:gap-6"
        courses={courses.slice(2, courses.length)}
        imgLoading={IMG_LOADING_LAZY}
      />
    </>
  );
}

Grid.propTypes = { courses: PropTypes.arrayOf(PropTypes.instanceOf(Course)).isRequired };

export default Grid;

import paypal from '~/graphics/card-icons/paypal.png';

function PayPal() {
  return (
    <div className="flex flex-row gap-4 mt-4 items-center">
      <img className="lg:w-16 md:w-12" src={paypal} alt="PayPal logo" />
      <p className="capitalize text-xl">PayPal</p>
    </div>
  );
}
export default PayPal;

import PropTypes from 'prop-types';

export interface ContentProps {
  content: string;
}

function Content({ content }: ContentProps) {
  if (!content.length) {
    return null;
  }

  return (
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

Content.propTypes = { content: PropTypes.string };
Content.defaultProps = { content: '' };

export default Content;

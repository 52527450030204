import PropTypes from 'prop-types';

import { Unit } from '~/models/Unit';
import CardContent from '~/components/unit/CardContent';
import UnitContext from '~/contexts/Unit';

export interface UnitCardProps {
  unit: Unit;
}

function Card({ unit }: UnitCardProps) {
  return (
    <UnitContext.Provider value={unit}>
      <CardContent />
    </UnitContext.Provider>
  );
}

Card.propTypes = { unit: PropTypes.instanceOf(Unit).isRequired };

export default Card;

import { Outlet } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { useEffect } from 'react';

import NavBar from '~/components/nav/NavBar';
import Footer from '~/components/nav/Footer';
import { useAuth } from '~/contexts/Auth';
import { getHash } from '~/api/launch-darkly';

export default function SignedIn() {
  const { user } = useAuth();
  const ldClient = useLDClient();

  useEffect(() => {
    async function identifyUser() {
      if (user && ldClient) {
        const ldUser: LDUser = {
          anonymous: false,
          key: user.uuid,
          email: user.email,
        };

        try {
          const hash = user.launchDarklyHash || await getHash(user.uuid);
          if (!user.launchDarklyHash) {
            localStorage.setItem('launchDarklyHash', hash);
          }

          await ldClient.identify(ldUser, hash);
        } catch (e) {
          const hash = await getHash(user.uuid);
          await ldClient.identify(ldUser, hash);
          localStorage.setItem('launchDarklyHash', hash);
        }
      }
    }
    identifyUser();
  }, [user, ldClient]);

  return (
    <div className="dark bg-gray-900 overflow-x-hidden min-h-screen flex flex-col items-stretch">
      <div className="flex-grow">
        <NavBar />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

import { ProviderConfig } from 'launchdarkly-react-client-sdk';

import {
  LAUNCHDARKLY_CLIENT_ID, ENV_NAME, APP_VERSION, APP_ID, LAUNCHDARKLY_ANONYMOUS_HASH,
} from '~/lib/constants';

const launchdarklyConfig: ProviderConfig = {
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
  user: {
    anonymous: true,
    key: 'anonymous',
  },
  options: {
    hash: LAUNCHDARKLY_ANONYMOUS_HASH,
    application: {
      id: `${APP_ID}-${ENV_NAME}`,
      version: APP_VERSION,
    },
  },
};

export default launchdarklyConfig;

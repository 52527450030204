import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';

import PageLoading from '~/components/layout/PageLoading';
import { getAccountData } from '~/api/account';
import Account, { AccountData } from '~/models/Account';
import { useAuth } from '~/contexts/Auth';

export interface AccountContextType {
  account: Account;
  updateAccount: (account: AccountData) => void;
}

const emptyAccount = new Account({});

export const AccountContext = createContext<AccountContextType>({
  account: emptyAccount,
  updateAccount: () => null,
});

export const useAccount = () => useContext(AccountContext);

export function AccountProvider({ children }: { children: React.ReactNode }) {
  const [account, setAccount] = useState<Account>(emptyAccount);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useAuth();
  useEffect(() => {
    async function getAccount() {
      const data = await getAccountData(auth.user.uuid);
      const accountObj = new Account(data);

      setAccount(accountObj);
      setLoading(false);
    }
    getAccount();
  }, []);

  const updateAccount = (newAccount: AccountData) => {
    const accountObj = new Account(newAccount);
    setAccount(accountObj);
  };

  const value = useMemo(() => ({
    account,
    updateAccount,
  }), [account, updateAccount]);
  return (
    loading ? <PageLoading /> : (
      <AccountContext.Provider value={value}>
        { children }
      </AccountContext.Provider>
    ));
}

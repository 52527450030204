import isArray from 'lodash/isArray';

import { BasicCourse, BasicCourseData } from '~/models/BasicCourse';
import { BasicCategory, BasicCategoryData } from '~/models/BasicCategory';
import { Tag, TagData } from '~/models/Tag';
import { Unit, UnitData } from '~/models/Unit';
import { Lesson } from '~/models/Lesson';
import { Video, VideoData } from '~/models/Video';
import { unitsWithLessons } from '~/lib/utils';

export enum FeaturedMediaEnum {
  video = 'video',
  image = 'image',
}

export type FeaturedMedia = `${FeaturedMediaEnum}`;

export interface CourseData extends BasicCourseData {
  category?: BasicCategoryData;
  tags?: TagData[];
  units?: UnitData[];
  featuredMediaType?: FeaturedMedia,
  video?: VideoData;
}

export class Course extends BasicCourse {
  category: BasicCategory;

  tags: Tag[];

  units: Unit[];

  featuredMediaType?: FeaturedMedia;

  video?: Video;

  constructor(data: CourseData = {}) {
    super(data);
    this.category = new BasicCategory(data.category || {});
    this.tags = isArray(data.tags) ? data.tags.map(tag => new Tag(tag)) : [];
    this.units = isArray(data.units) ? data.units.map(unit => new Unit(unit)) : [];
    this.featuredMediaType = data.featuredMediaType || undefined;
    this.video = data.video?.url ? new Video(data.video) : undefined;
  }

  get lessonCount(): number {
    return this.units.map(({ lessons }) => lessons).flat().length;
  }

  get hasSingleLesson(): boolean {
    return this.lessonCount === 1;
  }

  get progress(): number {
    const complete = this.units.map(({ lessons }) => lessons.filter(({ completed }) => completed)).flat().length;
    return Math.floor((complete / this.lessonCount) * 100);
  }

  get nextLesson(): Lesson {
    const withLessons = unitsWithLessons(this.units);
    if (!withLessons.length) {
      return new Lesson();
    }
    const lessonIndexes = withLessons
      .map((unit, unitIndex) => unit.lessons.map(({ completed }, lessonIndex) => ({ unitIndex, lessonIndex, completed })))
      .flat()
      .reverse();
    const lastCompletedIndex = lessonIndexes.findIndex(({ completed }) => completed);
    if (lastCompletedIndex < 1) {
      return withLessons[0].lessons[0];
    }
    const { unitIndex, lessonIndex } = lessonIndexes[lastCompletedIndex];
    if (lessonIndex + 1 < withLessons[unitIndex].lessons.length) {
      return withLessons[unitIndex].lessons[lessonIndex + 1];
    }
    return withLessons[unitIndex + 1].lessons[0];
  }

  get downloadCount(): number {
    return this.units.map(({ lessons }) => lessons.map(({ downloads }) => downloads).flat()).flat().length;
  }
}

export default Course;

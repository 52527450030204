export interface DownloadData {
  url?: string;
  title?: string;
}
export class Download {
  url: string;

  title: string;

  constructor(data: DownloadData = {}) {
    this.url = data.url || '';
    this.title = data.title || '';
  }
}

export default Download;

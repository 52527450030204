import { Loader } from 'react-feather';

function PurchaseComplete() {
  return (
    <div className="min-h-screen px-6 lg:px-20 max-w-md lg:max-w-screen-md mx-auto flex flex-col items-center text-center justify-center place-content-center">
      <h2 className="mt-8">Congratulations! 🎉</h2>
      <p className="my-6 mx-8 px-8">
        When your payment is done processing you will be redirected
        to SamCart.com for account setup.
      </p>
      <Loader size="30" color="white" className="animate-spin-slow" />
    </div>
  );
}

export default PurchaseComplete;

import PropTypes from 'prop-types';

import SlideImage from '~/components/home/SlideImage';
import { HeroSlide } from '~/models/HeroSlide';

export interface SlideProps {
  slide: HeroSlide;
  dragging: boolean;
}

function Slide({ slide, dragging }: SlideProps) {
  return (
    <div className="hero-slide">
      <a
        href={slide.url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={event => dragging && event.preventDefault()}
      >
        <SlideImage
          smallImage={slide.smallImage}
          mediumImage={slide.mediumImage}
          largeImage={slide.largeImage}
          altText={slide.altText}
        />
      </a>
    </div>
  );
}

Slide.propTypes = {
  slide: PropTypes.instanceOf(HeroSlide).isRequired,
  dragging: PropTypes.bool.isRequired,
};

export default Slide;

export interface BasicCategoryData {
  id?: string;
  slug?: string;
  name?: string;
}
export class BasicCategory {
  id: string;

  slug: string;

  name: string;

  constructor(data: BasicCategoryData = {}) {
    this.id = data.id || '';
    this.slug = data.slug || '';
    this.name = data.name || '';
  }
}

export default BasicCategory;

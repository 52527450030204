import { formatPriceWithCurrency } from '~/lib/utils';
import { PlanFrequency, PlanTier } from '~/pages/Checkout';
import classNames from '~/components/ui/utils';

export interface CardParameters {
  id: string;
  tier: PlanTier;
  productImage: string;
  monthlyPrice: number;
  monthlyBreakdownOfAnnualPrice: number;
  annualPrice: number;
  discount: number;
  featureList: JSX.Element;
}

export interface TieredCardProps {
  parameters: CardParameters;
  selectedTier: PlanTier;
  frequency: PlanFrequency;
  onClick(newTier: PlanTier): void;
}

function Card({
  parameters,
  selectedTier,
  frequency,
  onClick,
}: TieredCardProps) {
  return (
    <div
      className={classNames('checkout-card-wrapper', parameters.tier && `card-tier-${parameters.tier}`, parameters.tier === selectedTier ? 'card-tier-selected' : '')}
      onClick={() => onClick(parameters.tier)}
      onKeyPress={() => onClick(parameters.tier)}
      key={1}
      id={parameters.tier}
      role="button"
      tabIndex={0}
    >
      <div className="checkout-card-body">
        <p className="checkout-card-title">{parameters.tier}</p>
        {frequency === 'yearly' && parameters.discount > 0 && (
          <span className="checkout-card-discount">
            {`${parameters.discount}% off`}
          </span>
        )}
        <img className="checkout-card-plan-logo" src={parameters.productImage} alt={`SamCart ${parameters.tier} icon`} />
        <p className="product-price" data-testid="main-price">
          {`${(formatPriceWithCurrency(frequency === 'monthly' ? parameters.monthlyPrice : parameters.monthlyBreakdownOfAnnualPrice, 0))}`}
          <span className="checkout-card-billing-cycle">/MO</span>
        </p>
        {frequency === 'yearly' && (
          <>
            <p className="checkout-card-original-price">
              {`${formatPriceWithCurrency(parameters.monthlyPrice, 0)}/MO`}
            </p>
            <p className="checkout-annual-notice invisible md:visible mt-2">
              {`${formatPriceWithCurrency(parameters.annualPrice, 0)} Billed Annually`}
            </p>
          </>
        )}
      </div>
      <div className="checkout-card-feature-list">
        {parameters.featureList}
        {frequency === 'yearly' && (
          <p className="checkout-annual-notice visible md:invisible mt-2">
            {`${formatPriceWithCurrency(parameters.annualPrice, 0)} Billed Annually`}
          </p>
        )}
      </div>
    </div>
  );
}

export default Card;

import PropTypes from 'prop-types';

import Breadcrumb, { BreadcrumbProps } from '~/components/shared/Breadcrumb';

export interface BreadcrumbsProps {
  links: BreadcrumbProps[];
}

function Breadcrumbs({ links }: BreadcrumbsProps) {
  if (!links.length) {
    return null;
  }

  const breadcrumbs = [{ url: '/', label: 'Home' }, ...links];

  return (
    <ol className="breadcrumbs">
      {breadcrumbs.map(({ url, label }, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <li key={url} className="breadcrumb">
            {isLast
              ? <span className="breadcrumb-current">{label}</span>
              : <Breadcrumb url={url} label={label} />}
          </li>
        );
      })}
    </ol>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default Breadcrumbs;

import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

import { classNames } from '~/components/ui/utils';
import Logo from '~/components/nav/Logo';
import CloseSearchButton from '~/components/nav/CloseSearchButton';
import Search from '~/components/nav/Search';
import AccountMenu from '~/components/nav/AccountMenu';
import { NAVBAR_SCROLL_HIDE_THRESHOLD } from '~/lib/constants';
import samcartLogo from '~/graphics/samcart-logo.svg';
import { getSamCartOnboardingData, SamCartOnboardingData } from '~/api/data';
import { useAuth } from '~/contexts/Auth';
import Banner from '~/components/shared/Banner';

export type SearchOpenProp = boolean | undefined

function NavBar() {
  const auth = useAuth();
  const [searchOpen, setSearchOpen] = useState<SearchOpenProp>();
  const [searchValue, setSearchValue] = useState('');
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  const [displayBanner, setDisplayBanner] = useState(false);
  const [bannerData, setBannerData] = useState<SamCartOnboardingData>();

  const handleClose = () => {
    setSearchOpen(false);
    setSearchValue('');
  };

  const handleScroll = throttle(() => {
    const currentScrollPos = window.pageYOffset;
    setScrollPosition(currentScrollPos);
    const userIsScrollingUp = prevScrollPosition > currentScrollPos;
    setVisible((userIsScrollingUp) || currentScrollPos < NAVBAR_SCROLL_HIDE_THRESHOLD);
    setPrevScrollPosition(currentScrollPos);
  }, 250);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPosition, visible, handleScroll]);

  useEffect(() => {
    async function getBannerData() {
      const response = await getSamCartOnboardingData(auth.user.uuid);
      setBannerData(response);
    }
    getBannerData();
  }, []);

  useEffect(() => {
    if (bannerData?.isSamCartCustomer && bannerData?.link) {
      setDisplayBanner(true);
    }
  }, [bannerData, displayBanner]);

  return (
    <nav
      className={classNames(
        'nav-container',
        scrollPosition > NAVBAR_SCROLL_HIDE_THRESHOLD && 'nav-container-scrolled',
        visible ? 'nav-container-show' : 'nav-container-hide',
        displayBanner && 'nav-container-with-account-message',
      )}
    >
      <div
        className={classNames(
          'nav-bar',
          typeof searchOpen !== 'undefined' && (searchOpen ? 'search-open' : 'search-closed'),
          !!searchValue.length && 'nav-has-search-value',
        )}
      >
        <div className="nav-bar-items">
          <CloseSearchButton onClick={handleClose} />
          <Logo />
          <div className="pr-4 lg:pr-6 flex items-center">
            <Search
              open={searchOpen}
              setOpen={setSearchOpen}
              value={searchValue}
              setValue={setSearchValue}
            />
            <AccountMenu />
          </div>
        </div>
      </div>

      {displayBanner && (
        <Banner
          CTALink={bannerData?.link}
          label="Complete your SamCart Account Setup"
          image={samcartLogo}
          imageAltText="SamCart Logo"
          imageClassName="subscription-logo"
        />
      )}
    </nav>
  );
}

export default NavBar;

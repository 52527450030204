import Download from '~/components/lesson/Download';
import { useLessonContext } from '~/contexts/Lesson';

function LessonDetails() {
  const { downloads } = useLessonContext();

  if (!downloads.length) {
    return null;
  }

  return (
    <>
      <h5 className="mb-2">Downloads</h5>
      <div className="grid grid-cols-1 md:grid-cols-2">
        {downloads.map(download => (
          <Download key={download.url} download={download} />
        ))}
      </div>
    </>
  );
}

export default LessonDetails;

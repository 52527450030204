function PurchaseFailed() {
  return (
    <div className="min-h-screen px-6 lg:px-20 lg:max-w-screen-lg mx-auto flex flex-col items-center text-center justify-center place-content-center">
      <h2 className="mt-8 font-medium tracking-[-1px]">Something went wrong! 💔</h2>
      <p className="my-6 mx-8 px-8">
        Please try again.
        If the issue persists, please contact us at
        <a href="mailto:support@samcart.com"> support@samcart.com</a>
      </p>
    </div>
  );
}

export default PurchaseFailed;

/* eslint-disable react/no-array-index-key, react/display-name, react/jsx-props-no-spreading, jsx-a11y/label-has-associated-control, no-param-reassign, @typescript-eslint/no-explicit-any */
import { useState, forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { classNames } from '~/components/ui/utils';
import { SEARCH_RESULT_TYPES } from '~/lib/constants';
import { SearchType, SearchTypeOption, SearchData } from '~/api/data';
import { SearchOpenProp } from '~/components/nav/NavBar';

export type SearchResultItem = {
  name: string;
  slug: string;
  type: SearchTypeOption;
  label: string;
  link: string;
}

export interface SearchInputProps {
  data: SearchType[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<SearchOpenProp>>;
  loading: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
  const navigate = useNavigate();
  const {
    data,
    value,
    setValue,
    setOpen,
    loading,
  } = props;

  const items: SearchResultItem[] = data.map((item: SearchType) => item.data.map((result: SearchData) => ({
    name: result.name,
    slug: result.slug,
    type: item.type,
    label: ` in ${SEARCH_RESULT_TYPES[item.type].plural}`,
    link: `${SEARCH_RESULT_TYPES[item.type].path}/${result.slug}`,
  }))).flat();

  const [selectedItem, setSelectedItem] = useState<SearchResultItem | null>(null);

  const navigateTo = ({ link }: { link: string }) => {
    setOpen(false);
    navigate(link);
  };

  const handleChange = (item: SearchResultItem) => {
    setSelectedItem(null);
    setOpen(false);
    setValue('');
    navigateTo(item);
  };
  return (
    <div className="nav-search-input-container">
      <Combobox value={selectedItem} onChange={handleChange}>
        {({ open }) => (
          <>
            <Combobox.Input
              ref={ref}
              onChange={event => setValue(event.target.value)}
              className="nav-search-input"
              placeholder="Search categories, courses, tags"
            />
            {open && !!value.length && (
              <Combobox.Options
                className={classNames(
                  'nav-search-results-container',
                  open && 'nav-search-results-container-open',
                )}
              >
                {!items.length && !loading && (<li className="nav-search-result">No results found</li>)}
                {items.map((item, idx) => {
                  const matches = match(item.name, value);
                  const parts = parse(item.name, matches);
                  const key = `${item.type}-${item.name}-${idx}`;
                  return (
                    <Combobox.Option
                      key={key}
                      value={item}
                      as={Fragment}
                    >
                      {({ active }) => (
                        <li
                          key={key}
                          className={classNames('nav-search-result', active && 'nav-search-result-highlighted')}
                        >
                          {parts.map((part, partIndex) => (
                            <span
                              key={partIndex}
                              className={classNames(part.highlight && 'bold')}
                            >
                              {part.text}
                            </span>
                          ))}
                          <span className="type">
                            {item.label}
                          </span>
                        </li>
                      )}
                    </Combobox.Option>
                  );
                })}
              </Combobox.Options>
            )}
          </>
        )}
      </Combobox>
    </div>
  );
});

SearchInput.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf<SearchTypeOption>(['category', 'course', 'tag']).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }).isRequired).isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SearchInput;

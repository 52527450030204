import PropTypes from 'prop-types';
import { ArrowLeft } from 'react-feather';

export interface CloseSearchButtonProps {
  onClick(): void;
}

function CloseSearchButton({ onClick }: CloseSearchButtonProps) {
  return (
    <button
      className="nav-back-button nav-button"
      onClick={onClick}
      aria-label="Close search"
    >
      <ArrowLeft />
    </button>
  );
}

CloseSearchButton.propTypes = { onClick: PropTypes.func.isRequired };

export default CloseSearchButton;

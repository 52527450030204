function LinearLoadBar() {
  return (
    <div className="ui-load-bar">
      <div className="ui-load-bar-line" />
      <div className="ui-load-bar-sub-line ui-load-bar-inc" />
      <div className="ui-load-bar-sub-line ui-load-bar-dec" />
    </div>
  );
}

export default LinearLoadBar;

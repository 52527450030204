import { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/js';

import { API_PATHS } from '~/lib/constants';
import { getRequest } from '~/api';

export async function getHash(uuid: string): Promise<string> {
  try {
    const { data } = await getRequest(`${API_PATHS.USERS}/${uuid}/launchDarklyHash`);
    return data.launchDarklyHash || '';
  } catch (err) {
    const error = err as AxiosError;
    Bugsnag.notify(error);
    return Promise.reject(error.response?.data.error || 'Unable to get LaunchDarkly hash.');
  }
}

export default { getHash };

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAuth } from '~/contexts/Auth';
import { PATHS } from '~/lib/constants';
import { AccountProvider } from '~/contexts/Account';
import RequireActiveSubscription from '~/RequireActiveSubscription';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user.signedIn) {
      navigate(PATHS.LOGIN, { state: { from: location }, replace: true });
    }
  }, [auth.user.signedIn]);

  return (
    <AccountProvider>
      <RequireActiveSubscription>
        {children}
      </RequireActiveSubscription>
    </AccountProvider>
  );
}

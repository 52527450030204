import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronRight } from 'react-feather';

export interface BreadcrumbProps {
  url: string;
  label: string;
}

function Breadcrumb({ url, label }: BreadcrumbProps) {
  return (
    <>
      <Link to={url} aria-label={label}>
        <span className="breadcrumb-label">{label}</span>
        <ArrowLeft className="breadcrumb-back" />
      </Link>
      <ChevronRight className="breadcrumb-separator" />
    </>
  );
}

Breadcrumb.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Breadcrumb;

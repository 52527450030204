import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '~/contexts/Auth';
import { resetPassword } from '~/api/auth';
import { PATHS } from '~/lib/constants';
import Button from '~/components/ui/Button';
import ErrorMessage from '~/components/ui/ErrorMessage';
import useDocumentTitle from '~/hooks/title';
import PasswordFields from '~/components/auth/PasswordFields';

type ResetPasswordRouteParams = {
  token: string;
}

function ResetPasswordPage() {
  const { token } = useParams<ResetPasswordRouteParams>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState('');
  const [requirementError, setRequirementError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useDocumentTitle('Reset password');

  useEffect(() => {
    if (auth.user.signedIn) {
      auth.signout(() => null);
    }
    if (!token?.length) {
      navigate(PATHS.FORGOT);
    }
  }, [auth, token]);

  const handleChange = (func: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPristine(false);
    func(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPristine(true);
    setProcessing(true);
    resetPassword(password, confirmPassword, token || '')
      .then(() => {
        navigate(PATHS.LOGIN_PASSWORD_RESET);
      })
      .catch((err) => {
        setError(err);
        setProcessing(false);
      });
  };

  const passwordsMatch = (): boolean => !!password.length && !!confirmPassword.length && password === confirmPassword;

  return (
    <>
      <h3 className="mb-2">Reset password</h3>

      <form onSubmit={handleSubmit} aria-label="Reset password form">

        <PasswordFields
          passwordLabel="New Password"
          password={password}
          confirmPassword={confirmPassword}
          pristine={pristine}
          error={error}
          requirementError={requirementError}
          setRequirementError={setRequirementError}
          handleChangePassword={handleChange(setPassword)}
          handleChangeConfirmPassword={handleChange(setConfirmPassword)}
          processing={processing}
        />

        <div className="pt-6">
          <Button
            type="submit"
            label="Reset password"
            disabled={pristine || !passwordsMatch() || requirementError}
          />
        </div>

        <ErrorMessage error={pristine && !!error.length} message={error} />
      </form>

      <p className="pt-4"><Link to={PATHS.LOGIN}>Back to login</Link></p>
    </>
  );
}

export default ResetPasswordPage;

import {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';

import { Course, CourseData } from '~/models/Course';
import CourseHeader from '~/components/course/CourseHeader';
import PageLoading from '~/components/layout/PageLoading';
import CourseUnits from '~/components/course/CourseUnits';
import Breadcrumbs from '~/components/shared/Breadcrumbs';
import { PATHS } from '~/lib/constants';
import { getCourse } from '~/api/data';
import CourseContext from '~/contexts/Course';
import useDocumentTitle from '~/hooks/title';

type CourseRouteParams = {
  courseSlug: string;
  unitSlug?: string;
}

function CoursePage() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { courseSlug, unitSlug } = useParams<CourseRouteParams>();

  const [data, setData] = useState<CourseData>();

  useEffect(() => {
    async function getData() {
      if (courseSlug) {
        const response = await getCourse(courseSlug);
        setData(response);
        if (sectionRef.current && unitSlug) {
          sectionRef.current.scrollIntoView();
        }
      }
    }
    getData();
  }, [courseSlug, unitSlug]);

  const course = useMemo(() => new Course(data), [data]);

  useDocumentTitle(course.name);

  if (!course.id.length) {
    return <PageLoading />;
  }

  const links = [{
    url: `${PATHS.COURSE}/${course.slug}`,
    label: course.name,
  }];
  if (course.category.slug.length) {
    links.unshift({
      url: `${PATHS.CATEGORY}/${course.category.slug}`,
      label: course.category.name,
    });
  }

  return (
    <CourseContext.Provider value={course}>
      <div className="min-h-screen pt-14 xl:pt-16">
        <Breadcrumbs links={links} />
        <CourseHeader />
        <CourseUnits unitSlug={unitSlug} ref={sectionRef} />
      </div>
    </CourseContext.Provider>
  );
}

export default CoursePage;

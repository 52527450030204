import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import CreateAccountForm from '~/components/auth/CreateAccountForm';
import CreateAccountInvalid from '~/components/auth/CreateAccountInvalid';
import PageLoading from '~/components/layout/PageLoading';
import { useAuth } from '~/contexts/Auth';
import { validateSignupToken, createAccount, UserData } from '~/api/auth';
import { User } from '~/models/User';
import { PATHS } from '~/lib/constants';
import useDocumentTitle from '~/hooks/title';

type CreateAccountRouteParams = {
  token: string;
}

function CreateAccountPage() {
  const { token } = useParams<CreateAccountRouteParams>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState<UserData>();
  const [checkSignedIn, setCheckSignedIn] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const courseId = searchParams.get('course') || '';

  useDocumentTitle('Create account');

  useEffect(() => {
    if (checkSignedIn && auth.user.signedIn) {
      navigate('/');
    }

    let isLoaded = true;

    function getData() {
      if (token) {
        validateSignupToken(token)
          .then((response) => {
            if (isLoaded) {
              if (response.existing) {
                const user = new User(response);
                localStorage.setItem('cart', token);
                localStorage.setItem('email', user.email);
                navigate(PATHS.LOGIN_EXISTING_ACCOUNT);
              } else {
                setData(response);
              }
            }
          })
          .catch(() => {
            setInvalid(true);
          });
      }
    }

    getData();

    return () => {
      isLoaded = false;
    };
  }, [token, auth, checkSignedIn]);

  useEffect(() => {
    if (courseId) {
      localStorage.setItem('courseRedirect', courseId);
      setSearchParams({});
    }
  }, []);

  const user = useMemo(() => new User(data), [data]);

  const handleChange = (func: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPristine(false);
    func(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPristine(true);
    setProcessing(true);
    createAccount(
      user.firstName,
      user.lastName,
      user.email,
      password,
      token || '',
    )
      .then((response) => {
        setCheckSignedIn(false);
        auth.signin(response, () => {
          navigate(PATHS.SIGNUP_SUCCESS);
        });
      })
      .catch((err) => {
        setProcessing(false);
        setError(err);
      });
  };

  if (invalid) {
    return <CreateAccountInvalid />;
  }

  if (!data) {
    return <PageLoading />;
  }

  return (
    <CreateAccountForm
      user={user}
      password={password}
      confirmPassword={confirmPassword}
      pristine={pristine}
      error={error}
      handleSubmit={handleSubmit}
      handleChangePassword={handleChange(setPassword)}
      handleChangeConfirmPassword={handleChange(setConfirmPassword)}
      processing={processing}
    />
  );
}

export default CreateAccountPage;

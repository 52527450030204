import isArray from 'lodash/isArray';
import Bugsnag from '@bugsnag/js';
import { AxiosError } from 'axios';

import { IMG_LOADING_LAZY, IMG_LOADING_EAGER, API_PATHS } from '~/lib/constants';
import { theme } from '~/styles/utils';
import Lesson, { LessonData } from '~/models/Lesson';
import { HeroSlideData } from '~/models/HeroSlide';
import { getRequest, postRequest } from '~/api';
import { CategoryData } from '~/models/Category';
import { CourseData } from '~/models/Course';
import { TaggedData } from '~/models/Tagged';

export enum SearchTypesEnum {
  category = 'category',
  course = 'course',
  tag = 'tag'
}

export type SearchTypeOption = `${SearchTypesEnum}`;
export type ImgLoadingAttribute = 'eager' | 'lazy' | undefined

export interface BreakpointSetting {
  breakpoint: number;
  slides: number;
}

export interface SamCartOnboardingData {
  isSamCartCustomer: boolean;
  link: string;
  samCartBillingPageUrl: string;
}

export interface SamCartTieredProductData {
  id: string;
  frequency: string;
  monthlyPrice: number;
  recurringMonthlyPrice: number;
  monthlyProductId: number;
  tierName: string;
  yearlyPrice: number;
  recurringYearlyPrice: number;
  yearlyProductId: number;
  discount: number;
  productImage: string;
  featureList: JSX.Element;
}

export interface SearchData {
  name: string;
  slug: string;
}

export interface SearchType {
  type: SearchTypeOption;
  data: SearchData[];
}

export interface HomepageData {
  category: CategoryData;
  slidesToShow: number;
  breakpoints: BreakpointSetting[];
  imgLoading: ImgLoadingAttribute;
}

export async function getSearchData(): Promise<SearchType[]> {
  const { data } = await getRequest(API_PATHS.SEARCH);
  return data;
}

export async function marketingContent(): Promise<HeroSlideData[]> {
  const { data } = await getRequest(API_PATHS.HERO_SLIDES);
  return data;
}

export async function getHomepageData(): Promise<HomepageData[]> {
  const { data } = await getRequest(API_PATHS.COURSES);
  const breakpointNum = (breakpoint: string): number => parseInt(theme.screens[breakpoint].replace('px', ''), 10);

  return data.map((category: CategoryData, index: number) => {
    let slidesToShow: number;
    let breakpoints: BreakpointSetting[];
    const imgLoading: ImgLoadingAttribute = index === 0 ? IMG_LOADING_EAGER : IMG_LOADING_LAZY;

    const numOfCourses = isArray(category.courses) ? category.courses.length : 0;

    if (index === 0 || numOfCourses === 2) {
      slidesToShow = 2;
      breakpoints = [{ breakpoint: breakpointNum('md'), slides: 2 }, { breakpoint: breakpointNum('sm'), slides: 1 }];
    } else if (index === 1 || index === 3 || numOfCourses === 3) {
      slidesToShow = 3;
      breakpoints = [{ breakpoint: breakpointNum('xl'), slides: 3 }, { breakpoint: breakpointNum('lg'), slides: 2 }, { breakpoint: breakpointNum('sm'), slides: 1 }];
    } else {
      slidesToShow = 4;
      breakpoints = [{ breakpoint: breakpointNum('xl'), slides: 4 }, { breakpoint: breakpointNum('lg'), slides: 3 }, { breakpoint: breakpointNum('sm'), slides: 2 }];
    }

    return {
      slidesToShow,
      breakpoints,
      imgLoading,
      category,
    };
  });
}

export async function getCourse(slug: string): Promise<CourseData> {
  const { data } = await getRequest(`${API_PATHS.COURSES}/${slug}`);
  return data;
}

export async function getCategory(slug: string): Promise<CategoryData> {
  const { data } = await getRequest(`${API_PATHS.CATEGORIES}/${slug}`);
  return data;
}

export async function getTagged(slug: string): Promise<TaggedData> {
  const { data } = await getRequest(`${API_PATHS.TAGS}/${slug}`);
  return data;
}

export async function getLesson(lessonSlug: string, courseSlug: string): Promise<LessonData> {
  const { data } = await getRequest(`${API_PATHS.COURSES}/${courseSlug}${API_PATHS.LESSON}/${lessonSlug}`);
  return data;
}

export async function updateLesson(lesson: Lesson): Promise<boolean> {
  try {
    await postRequest(`${API_PATHS.COURSES}/${lesson.course.slug}${API_PATHS.LESSON}/${lesson.slug}/complete`);
    return true;
  } catch (err) {
    const error = err as AxiosError;
    Bugsnag.notify(error);
    return false;
  }
}

export async function getSamCartOnboardingData(userSlug: string): Promise<SamCartOnboardingData> {
  try {
    const {
      data = {
        isSamCartCustomer: false,
        link: '',
      },
    } = await getRequest(`${API_PATHS.USERS}/${userSlug}${API_PATHS.SAMCART_ONBOARDING}`);
    return data.data as SamCartOnboardingData;
  } catch (err) {
    const error = err as AxiosError;
    Bugsnag.notify(error);
    return {
      isSamCartCustomer: false,
      link: '',
    } as SamCartOnboardingData;
  }
}

export async function getSamCartTieredProducts(productType: string): Promise<SamCartTieredProductData[]> {
  const { data: { data } = {} } = await getRequest(`${API_PATHS.PRODUCTS}/${productType}`);
  return data;
}

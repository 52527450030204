import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';
import { Dialog, Transition } from '@headlessui/react';

import { Video } from '~/models/Video';
import VideoPlayer from '~/components/shared/VideoPlayer';
import { WELCOME_VIDEO_URL } from '~/lib/constants';
import { ReactComponent as LogoDarkMobile } from '~/graphics/brandmark-mobile-knockout.svg';
import { ReactComponent as LogoDark } from '~/graphics/brandmark-knockout.svg';

export interface WelcomeVideoModalProps {
  open: boolean;
  handleClose(): void;
}

function WelcomeVideoModal({ open, handleClose }: WelcomeVideoModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-70" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block h-screen w-full md:max-w-2xl lg:max-w-3xl xl:max-w-4xl md:h-auto p-4 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl md:rounded-lg">
              <Dialog.Title
                as="div"
                className="relative"
              >
                <LogoDarkMobile className="block mx-auto w-32 md:hidden" />
                <LogoDark className="hidden mx-auto w-56 xl:w-64 md:block" />
                <button
                  className="nav-button absolute top-0 left-0 md:left-auto md:right-0"
                  onClick={handleClose}
                  aria-label="Close video"
                >
                  <X />
                </button>
              </Dialog.Title>
              <div className="p-2 mt-4">
                <VideoPlayer autoplay video={new Video({ url: WELCOME_VIDEO_URL })} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

WelcomeVideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default WelcomeVideoModal;

import PropTypes from 'prop-types';
import { Search } from 'react-feather';

import { SearchOpenProp } from '~/components/nav/NavBar';

export interface OpenSearchButtonProps {
  open?: SearchOpenProp;
  onClick(): void;
}

function OpenSearchButton({ open, onClick }: OpenSearchButtonProps) {
  return (
    <button
      disabled={open}
      className="nav-search-button nav-button"
      onClick={onClick}
      aria-label="Open search"
    >
      <Search />
    </button>

  );
}

OpenSearchButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
OpenSearchButton.defaultProps = { open: undefined };

export default OpenSearchButton;

import { Link, Outlet } from 'react-router-dom';

import { APP_NAME } from '~/lib/constants';
import { ReactComponent as LogoDark } from '~/graphics/brandmark-knockout.svg';

export default function SignedOut() {
  return (
    <div className="dark bg-gray-900 min-h-screen overflow-x-hidden relative md:bg-auth md:bg-cover md:bg-center">
      <div className="w-full md:w-140 bg-gray-900 md:rounded-lg md:mx-auto md:mt-28 md:mb-6">
        <div className="px-4 pt-10 md:px-10">
          <Link to="/" className="pb-2" aria-label={`${APP_NAME} homepage`}>
            <LogoDark className="hidden w-72 lg:w-96 dark:block" />
          </Link>
        </div>

        <div className="px-4 py-10 md:px-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

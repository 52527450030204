import PropTypes from 'prop-types';

import Input from '~/components/ui/Input';
import Button from '~/components/ui/Button';

export interface ForgotFormProps {
  email: string;
  pristine: boolean;
  error: boolean;
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
  processing: boolean;
}

function ForgotForm({
  email,
  pristine,
  error,
  handleSubmit,
  handleChange,
  processing,
}: ForgotFormProps) {
  return (
    <form onSubmit={handleSubmit} aria-label="Forgot password form">
      <h3 className="mb-2">Forgot password</h3>
      <p>Enter your email below to reset your password.</p>

      <div className="pt-8">
        <Input
          id="email"
          label="Email address"
          name="email"
          type="text"
          disabled={processing}
          value={email}
          onChange={handleChange}
          error={pristine && error}
          errorMessage="Invalid email format"
        />
      </div>

      <div className="pt-8">
        <Button
          type="submit"
          label="Reset password"
          disabled={pristine || !email.length}
        />
      </div>
    </form>
  );
}

ForgotForm.propTypes = {
  email: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default ForgotForm;

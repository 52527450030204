import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface LaunchDarklyFlags extends LDFlagSet {
    maintenanceMode: boolean;
    hasAccountPage: boolean;
    hasBackendSearch: boolean;
  }

function useFeatureFlags() {
  return useFlags<LaunchDarklyFlags>();
}

export default useFeatureFlags;

export interface VideoData {
  url?: string;
}
export class Video {
  url: string;

  constructor(data: VideoData = {}) {
    this.url = (data.url || '').replace(/#$/, ''); // remove trailing '#'
  }
}

export default Video;

import { StrictMode } from 'react';
import { render } from 'react-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import '~/styles/main.css';
import App from '~/App';
import ScrollToTop from '~/components/layout/ScrollToTop';
import history from '~/history';
import ErrorBoundary from '~/ErrorBoundary';

const renderApp = async () => {
  render(
    <ErrorBoundary>
      <StrictMode>
        <HistoryRouter history={history}>
          <ScrollToTop />
          <App />
        </HistoryRouter>
      </StrictMode>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
};

renderApp();

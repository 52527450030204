import { hashCode } from '~/lib/utils';

export interface TagData {
  id?: string;
  slug?: string;
  name?: string;
}
export class Tag {
  id: string;

  slug: string;

  name: string;

  constructor(data: TagData = {}) {
    this.id = data.id || '';
    this.slug = data.slug || '';
    this.name = data.name || '';
  }

  get color(): string {
    const colors = [
      'bg-blue-800 hover:bg-blue-700',
      'bg-gray-700 hover:bg-gray-600',
      'bg-aqua-900 hover:bg-aqua-800',
      'bg-red-900 hover:bg-red-800',
      'bg-purple-700 hover:bg-purple-600',
    ];
    return colors[hashCode(this.name) % colors.length];
  }
}

export default Tag;

export interface ImageUploadData {
  url?: string;
  title?: string;
  altText?: string;
  caption?: string;
}

export class ImageUpload {
  url: string;

  title: string;

  altText: string;

  caption: string;

  constructor(data: ImageUploadData = {}) {
    this.url = data.url || '';
    this.title = data.title || '';
    this.altText = data.altText || '';
    this.caption = data.caption || '';
  }
}

export default ImageUpload;

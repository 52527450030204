import PropTypes from 'prop-types';

import { SHORT_DESCRIPTION_LENGTH } from '~/lib/constants';

export interface DescriptionProps {
  description: string;
  truncate: boolean;
}

function Description({ description, truncate }: DescriptionProps) {
  if (!description.length) {
    return null;
  }

  return (
    <p className="description">
      {truncate
        ? `${description.substring(0, SHORT_DESCRIPTION_LENGTH).trim()}${description.length > SHORT_DESCRIPTION_LENGTH ? '...' : ''}`
        : description}
    </p>
  );
}

Description.propTypes = {
  description: PropTypes.string,
  truncate: PropTypes.bool,
};
Description.defaultProps = {
  description: '',
  truncate: false,
};

export default Description;

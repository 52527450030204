import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { User } from 'react-feather';

import classNames from '~/components/ui/utils';
import { PATHS } from '~/lib/constants';
import { useAuth } from '~/contexts/Auth';
import useFeatureFlags from '~/hooks/feature-flags';

function AccountMenu() {
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSignout = () => {
    auth.signout(() => {
      navigate(PATHS.LOGIN);
    });
  };

  const { hasAccountPage } = useFeatureFlags();

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button aria-label="Account menu" className={classNames('nav-account-button', open ? 'nav-button-open' : 'nav-button-inverted')}>
            <User />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 w-52 mt-1 origin-top-right bg-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item disabled>
                  <div className="px-3 py-2">
                    <p className="mb-2 body3 uppercase dark:text-gray-400">
                      {auth.user.firstName}
                      {' '}
                      {auth.user.lastName}
                    </p>
                    <p className="body3 dark:text-gray-400 truncate">{auth.user.email}</p>
                  </div>
                </Menu.Item>
                {
                  hasAccountPage && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => navigate('/account')}
                          className={classNames('group flex items-center w-full p-3 body1', active ? 'bg-gray-700 text-white' : 'text-white')}
                        >
                          Account
                        </button>
                      )}
                    </Menu.Item>
                  )
                }
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleSignout}
                      className={classNames('group flex items-center w-full p-3 body1', active ? 'bg-gray-700 text-white' : 'text-white')}
                    >
                      Sign Out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default AccountMenu;

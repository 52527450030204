import { Link } from 'react-router-dom';

import { PARENT_COMPANY_NAME, PATHS } from '~/lib/constants';

function LoginHeaderExistingAccount() {
  return (
    <>
      <h3 className="mb-2">Welcome back!</h3>
      <p>
        It looks like we found a
        {' '}
        {PARENT_COMPANY_NAME}
        {' '}
        account matching the email you provided.
      </p>
      <p className="pt-4">
        You can log in with your
        {' '}
        {PARENT_COMPANY_NAME}
        {' '}
        password using the form below.
      </p>
      <p className="pt-4">
        If you can&apos;t remember your
        {' '}
        {PARENT_COMPANY_NAME}
        {' '}
        password, you can reset it using the
        {' '}
        <Link to={PATHS.FORGOT}>forgot password</Link>
        {' '}
        functionality.
      </p>
    </>
  );
}

export default LoginHeaderExistingAccount;

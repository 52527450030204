import PropTypes from 'prop-types';

import { Course } from '~/models/Course';
import Row from '~/components/grid/Row';
import { IMG_LOADING_LAZY } from '~/lib/constants';
import { CourseListProps } from '~/types/components';

function TaggedCourses({ courses }: CourseListProps) {
  if (!courses.length) {
    return null;
  }

  return (
    <div className="px-6 lg:px-12">
      <h4 className="mb-4">Courses</h4>
      <Row
        className="grid grid-cols-1 md:grid-cols-3 md:gap-6"
        courses={courses}
        imgLoading={IMG_LOADING_LAZY}
      />
    </div>
  );
}

TaggedCourses.propTypes = { courses: PropTypes.arrayOf(PropTypes.instanceOf(Course)).isRequired };

export default TaggedCourses;

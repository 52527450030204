function ScaleFeatureList() {
  return (
    <>
      <h2 className="checkout-card-feature-list-header mt-6">All Grow features, plus:</h2>
      <ul className="checkout-card-feature-list-wrapper">
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">5 admin users</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Priority</span>
          <span>email support</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Cart Abandonment</span>
          <span>app</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Subscription Saver</span>
          <span>app</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span>Built-in</span>
          <span className="font-bold">A/B testing</span>
        </li>
        <li className="checkout-card-feature-list-item">
          <span className="font-bold">Affiliate Center</span>
        </li>
      </ul>
    </>
  );
}

export default ScaleFeatureList;

import { Fragment } from 'react';
import { X } from 'react-feather';
import { Dialog, Transition } from '@headlessui/react';

export interface ModalProps {
  open: boolean,
  handleClose(): void,
  title?: string,
  body: JSX.Element,
  acceptButton: JSX.Element,
  rejectButton: JSX.Element,
}

function Modal({
  open,
  handleClose,
  title,
  body,
  rejectButton,
  acceptButton,
}: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-[2px]"
        onClose={handleClose}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-70" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block h-screen w-full md:max-w-2xl lg:m ax-w-3xl xl:max-w-4xl md:h-auto p-4 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl md:rounded-lg">
              <Dialog.Title
                as="div"
                className={`relative ${title ? ' border-bottom border-gray-800 border-b pb-6' : ''}`}
              >
                {title && <p className="text-white text-xl font-medium mt-1 pt-1 ml-6">{title}</p>}
                <button
                  className="nav-button absolute top-0 left-0 h-8 w-8 md:left-auto md:right-6"
                  onClick={handleClose}
                  aria-label="Close account management"
                >
                  <X />
                </button>
              </Dialog.Title>
              {body}
              <div className="flex flow-row justify-between border-top border-gray-800 border-t pt-8">
                <div className="w-32 flex-initial">
                  {rejectButton}
                </div>
                <div className="w-48 flex-initial">
                  {acceptButton}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;

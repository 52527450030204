import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import { Category, CategoryData } from '~/models/Category';
import { getCategory } from '~/api/data';
import PageLoading from '~/components/layout/PageLoading';
import Grid from '~/components/grid/Grid';
import SearchHeader from '~/components/shared/SearchHeader';
import Breadcrumbs from '~/components/shared/Breadcrumbs';
import { PATHS } from '~/lib/constants';
import useDocumentTitle from '~/hooks/title';

type CategoryRouteParams = {
  slug: string;
}

function CategoryPage() {
  const { slug } = useParams<CategoryRouteParams>();
  const [data, setData] = useState<CategoryData>();

  useEffect(() => {
    async function getData() {
      if (slug) {
        const response = await getCategory(slug);
        setData(response);
      }
    }
    getData();
  }, [slug]);

  const category = useMemo(() => new Category(data), [data]);

  useDocumentTitle(category.name);

  if (!category.id.length) {
    return <PageLoading />;
  }

  return (
    <div className="pt-14 xl:pt-16">
      <Breadcrumbs
        links={[{
          url: `${PATHS.CATEGORY}/${category.slug}`,
          label: category.name,
        }]}
      />
      <div className="page-container">
        <SearchHeader
          title={category.name}
          subtitle={`${category.courses.length} ${pluralize('course', category.courses.length)} in this category`}
        />
        <Grid courses={category.courses} />
      </div>
    </div>
  );
}

export default CategoryPage;

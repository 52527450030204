import { useNavigate } from 'react-router-dom';
import { Play, RotateCcw } from 'react-feather';

import Button from '~/components/ui/Button';
import { PATHS } from '~/lib/constants';
import { useLessonContext } from '~/contexts/Lesson';
import { useCourseContext } from '~/contexts/Course';

function NextButton() {
  const navigate = useNavigate();
  const { slug, units, hasSingleLesson } = useCourseContext();
  const lesson = useLessonContext();
  const nextLesson = lesson.nextLesson(units);
  const lastInCourse = lesson.isLastInCourse(units);
  const lastInUnit = lesson.isLastInUnit(units);

  let icon = 'play';
  let label = 'Next lesson';
  if (lastInCourse) {
    icon = 'restart';
    label = 'Restart course';
  } else if (lastInUnit) {
    label = 'Next unit';
  }

  const handleNavigate = () => navigate(`${PATHS.COURSE}/${slug}${PATHS.UNIT}/${nextLesson.unit.slug}${PATHS.LESSON}/${nextLesson.slug}`);

  if (hasSingleLesson) {
    return null;
  }

  return (
    <div className="md:flex md:justify-end md:items-end lg:flex-col">
      {!lastInCourse && (
        <div className="flex items-start mb-6 md:mb-0 md:flex-col md:items-end md:mr-6 lg:mr-0 lg:mb-6">
          <h6 className="eyebrow whitespace-nowrap mr-6 md:mr-0 md:mb-4">Next up</h6>
          <p className="md:text-right">{nextLesson.name}</p>
        </div>
      )}
      <div className="w-40">
        <Button
          name="start"
          label={label}
          type="button"
          variant="secondary"
          leadingIcon={icon === 'play' ? <Play /> : <RotateCcw />}
          onClick={handleNavigate}
        />
      </div>
    </div>
  );
}

export default NextButton;

import { Download } from 'react-feather';
import pluralize from 'pluralize';

import { useCourseContext } from '~/contexts/Course';

function DownloadCount() {
  const { downloadCount } = useCourseContext();

  if (!downloadCount) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Download className="text-white mr-2" />
      <p>
        {downloadCount}
        {' '}
        {pluralize('Download', downloadCount)}
      </p>
    </div>
  );
}

export default DownloadCount;

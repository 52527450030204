import isArray from 'lodash/isArray';

import { Video, VideoData } from '~/models/Video';
import { Download, DownloadData } from '~/models/Download';
import { Tag, TagData } from '~/models/Tag';
import { BasicCourse, BasicCourseData } from '~/models/BasicCourse';
import { BasicUnit, BasicUnitData } from '~/models/BasicUnit';
import { Unit } from '~/models/Unit';
import { unitsWithLessons } from '~/lib/utils';

export interface LessonMetadata {
  chatEmbedUrl?: string;
}
export interface LessonData {
  id?: string;
  slug?: string;
  name?: string;
  content?: string;
  completed?: boolean;
  course?: BasicCourseData;
  unit?: BasicUnitData;
  downloads?: DownloadData[];
  tags?: TagData[];
  video?: VideoData;
  metadata?: LessonMetadata;
}

export class Lesson {
  id: string;

  slug: string;

  name: string;

  content?: string;

  completed: boolean;

  course: BasicCourse;

  unit: BasicUnit;

  video: Video;

  downloads: Download[];

  tags: Tag[];

  chatEmbedUrl?: string;

  constructor(data: LessonData = {}) {
    this.id = data.id || '';
    this.slug = data.slug || '';
    this.name = data.name || '';
    this.content = data.content || '';
    this.completed = data.completed || false;
    this.course = new BasicCourse(data.course);
    this.unit = new BasicUnit(data.unit);
    this.video = new Video(data.video);
    this.downloads = isArray(data.downloads) ? data.downloads.map(download => new Download(download)) : [];
    this.tags = isArray(data.tags) ? data.tags.map(tag => new Tag(tag)) : [];
    this.chatEmbedUrl = data.metadata?.chatEmbedUrl;
  }

  isLastInCourse(units: Unit[]): boolean {
    return unitsWithLessons(units).map(unit => unit.lessons.map(({ id }) => id)).flat().reverse()[0] === this.id;
  }

  isLastInUnit(units: Unit[]): boolean {
    return unitsWithLessons(units).find(({ id }) => id === this.unit.id)?.lessons.map(({ id }) => id).reverse()[0] === this.id;
  }

  nextLesson(units: Unit[]): Lesson {
    const withLessons = unitsWithLessons(units);
    const unitIndex = withLessons.findIndex(({ id }) => id === this.unit.id);
    if (unitIndex === -1) {
      throw new Error('The lesson\'s unit was not found in array of units passed.');
    }
    const unit = withLessons[unitIndex];
    const lessonIndex = unit.lessons.findIndex(({ id }) => id === this.id);
    if (lessonIndex === -1) {
      throw new Error('The lesson was not found in array of units passed.');
    }
    if (lessonIndex + 1 === unit.lessons.length) {
      if (unitIndex + 1 === withLessons.length) {
        return withLessons[0].lessons[0];
      }
      return withLessons[unitIndex + 1].lessons[0];
    }
    return withLessons[unitIndex].lessons[lessonIndex + 1];
  }
}

export default Lesson;

import isArray from 'lodash/isArray';

import { Tag, TagData } from '~/models/Tag';
import { Lesson, LessonData } from '~/models/Lesson';
import { BasicCourse, BasicCourseData } from '~/models/BasicCourse';
import { BasicUnit, BasicUnitData } from '~/models/BasicUnit';

export interface UnitData extends BasicUnitData {
  course?: BasicCourseData;
  tags?: TagData[];
  lessons?: LessonData[];
}
export class Unit extends BasicUnit {
  course: BasicCourse;

  tags: Tag[];

  lessons: Lesson[];

  constructor(data: UnitData = {}) {
    super(data);
    this.course = new BasicCourse(data.course);
    this.tags = isArray(data.tags) ? data.tags.map(tag => new Tag(tag)) : [];
    this.lessons = isArray(data.lessons)
      ? data.lessons.map(lesson => new Lesson({
        ...lesson,
        unit: {
          id: this.id,
          slug: this.slug,
          name: this.name,
        },
      }))
      : [];
  }
}

export default Unit;

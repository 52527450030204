import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { HeroSlide } from '~/models/HeroSlide';
import Slide from '~/components/home/Slide';
import WelcomeSlide from '~/components/home/WelcomeSlide';
import { HERO_SLIDE_PAUSE_MILLISECONDS } from '~/lib/constants';

export interface HeroProps {
  slides: HeroSlide[]
}

function Hero({ slides }: HeroProps) {
  const [dragging, setDragging] = useState(false);
  const sliderRef = useRef<Slider>(null);

  return (
    <div className="hero">
      <Slider
        dots
        autoplay
        ref={sliderRef}
        autoplaySpeed={HERO_SLIDE_PAUSE_MILLISECONDS}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        beforeChange={() => setDragging(true)}
        afterChange={() => setDragging(false)}
      >
        <WelcomeSlide
          pause={sliderRef?.current?.slickPause}
          play={sliderRef?.current?.slickPlay}
        />
        {slides.map(slide => (
          <Slide
            key={slide.id}
            slide={slide}
            dragging={dragging}
          />
        ))}
      </Slider>
    </div>
  );
}

Hero.propTypes = { slides: PropTypes.arrayOf(PropTypes.instanceOf(HeroSlide).isRequired).isRequired };

export default Hero;

import PropTypes from 'prop-types';

import { classNames } from '~/components/ui/utils';

export interface UiLabelProps {
  id?: string;
  label?: string;
  error?: boolean;
  before?: React.ReactNode;
  after?:React.ReactNode;
}
function Label({
  id,
  label,
  error,
  before,
  after,
}: UiLabelProps) {
  if (!label) {
    return null;
  }
  return (
    <label
      htmlFor={id}
      className="ui-input-label"
    >
      {before}
      <span className={classNames(error && 'ui-error')}>{label}</span>
      {after}
    </label>
  );
}

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  before: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  after: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Label.defaultProps = {
  id: null,
  label: null,
  error: false,
  before: null,
  after: null,
};

export default Label;

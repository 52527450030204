import PropTypes from 'prop-types';

import { ImgLoadingAttribute } from '~/api/data';
import { IMG_LOADING_LAZY } from '~/lib/constants';
import ImageUpload from '~/models/ImageUpload';

export interface ThumbnailProps {
  image: ImageUpload;
  fallbackAlt: string;
  imgLoading?: ImgLoadingAttribute;
}

function Thumbnail({ image, fallbackAlt, imgLoading }: ThumbnailProps) {
  return (
    <div className="thumbnail">
      <img
        src={image.url}
        alt={image.altText.length ? image.altText : fallbackAlt}
        loading={imgLoading}
      />
    </div>
  );
}

Thumbnail.propTypes = {
  image: PropTypes.instanceOf(ImageUpload).isRequired,
  fallbackAlt: PropTypes.string.isRequired,
  imgLoading: PropTypes.string,
};

Thumbnail.defaultProps = { imgLoading: IMG_LOADING_LAZY };

export default Thumbnail;

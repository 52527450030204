import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import { Tagged, TaggedData } from '~/models/Tagged';
import { getTagged } from '~/api/data';
import PageLoading from '~/components/layout/PageLoading';
import TaggedCourses from '~/components/grid/TaggedCourses';
import TaggedUnits from '~/components/unit/TaggedUnits';
import TaggedLessons from '~/components/lesson/TaggedLessons';
import SearchHeader from '~/components/shared/SearchHeader';
import { PATHS } from '~/lib/constants';
import Breadcrumbs from '~/components/shared/Breadcrumbs';
import useDocumentTitle from '~/hooks/title';

type TagRouteParams = {
  slug: string;
}

function TagPage() {
  const { slug } = useParams<TagRouteParams>();
  const [data, setData] = useState<TaggedData>();

  useEffect(() => {
    async function getData() {
      if (slug) {
        const response = await getTagged(slug);
        setData(response);
      }
    }
    getData();
  }, [slug]);

  const tagged = useMemo(() => new Tagged(data), [data]);

  useDocumentTitle(tagged.name);

  if (!tagged.id.length) {
    return <PageLoading />;
  }

  return (
    <div className="pt-14 xl:pt-16">
      <Breadcrumbs
        links={[{
          url: `${PATHS.TAG}/${tagged.slug}`,
          label: tagged.name,
        }]}
      />
      <div className="page-container">
        <SearchHeader
          title={tagged.name}
          subtitle={`${tagged.itemsTagged} ${pluralize('item', tagged.itemsTagged)} tagged`}
        />
        <TaggedCourses courses={tagged.courses} />
        <TaggedUnits units={tagged.units} />
        <TaggedLessons lessons={tagged.lessons} />
      </div>
    </div>
  );
}

export default TagPage;

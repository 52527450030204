import { Video } from 'react-feather';
import pluralize from 'pluralize';

import { useCourseContext } from '~/contexts/Course';

function LessonCount() {
  const { lessonCount } = useCourseContext();

  return (
    <div className="flex items-center mr-6">
      <Video className="text-white mr-2" />
      <p>
        {lessonCount}
        {' '}
        {pluralize('Lesson', lessonCount)}
      </p>
    </div>
  );
}

export default LessonCount;

import PropTypes from 'prop-types';
import { CustomArrowProps } from 'react-slick';
import { ChevronRight } from 'react-feather';

function NextArrow({ className, onClick }: CustomArrowProps) {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label="Next slides"
    >
      <ChevronRight />
    </button>
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  onClick: null,
};

export default NextArrow;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Play } from 'react-feather';

import Button from '~/components/ui/Button';
import SlideImage from '~/components/home/SlideImage';
import WelcomeVideoModal from '~/components/home/WelcomeVideoModal';
import { APP_NAME, APP_SHORT_NAME } from '~/lib/constants';
import smallHero from '~/graphics/hero-sm.jpg';
import mediumHero from '~/graphics/hero-md.jpg';
import largeHero from '~/graphics/hero-lg.jpg';

export interface WelcomeSlideProps {
  play(): void;
  pause(): void;
}

function WelcomeSlide({ play, pause }: WelcomeSlideProps) {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    play();
  };

  const openModal = () => {
    setOpen(true);
    pause();
  };

  return (
    <div className="hero-slide">
      <SlideImage
        smallImage={smallHero}
        mediumImage={mediumHero}
        largeImage={largeHero}
        altText={`Welcome to ${APP_NAME}`}
      />
      <div className="absolute block w-full text-center lg:w-52 bottom-10 lg:left-12">
        <div className="w-52 mx-auto">
          <Button
            name="start"
            label={`Welcome to ${APP_SHORT_NAME}`}
            type="button"
            variant="secondary"
            leadingIcon={<Play />}
            onClick={openModal}
          />
        </div>
      </div>

      <WelcomeVideoModal
        open={open}
        handleClose={closeModal}
      />

    </div>
  );
}

WelcomeSlide.propTypes = {
  play: PropTypes.func,
  pause: PropTypes.func,
};

WelcomeSlide.defaultProps = {
  play: () => null,
  pause: () => null,
};

export default WelcomeSlide;

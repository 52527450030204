import PropTypes from 'prop-types';

import { classNames } from '~/components/ui/utils';
import { VARIANT_CLASS_NAME, SIZE_CLASS_NAME, COLOR_CLASS_NAME } from '~/components/ui/lib/constants';

export type ButtonType = 'submit' | 'reset' | 'button';
export type ButtonVariant = 'primary' |
  'secondary' |
  'link' |
  'iconLink' |
  'outlined';
export type ButtonSize = 'large' | 'small';
export type ButtonColor = 'success' | 'warning' | 'white' | 'default';

export interface UiButtonProps {
  id?: string;
  type: ButtonType;
  size: ButtonSize,
  variant: ButtonVariant,
  color: ButtonColor
  name?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  disabled?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
}

function Button({
  id,
  type,
  size,
  variant,
  color,
  name,
  onClick,
  label,
  disabled,
  leadingIcon,
  trailingIcon,
}: UiButtonProps) {
  return (
    <button
      id={id}
      type={type}
      name={name}
      onClick={onClick}
      disabled={disabled}
      className={classNames('ui-button', VARIANT_CLASS_NAME[variant], SIZE_CLASS_NAME[size], COLOR_CLASS_NAME[color], disabled && 'ui-disabled')}
    >
      {leadingIcon && (
        <span className="leading-icon">
          {leadingIcon}
        </span>
      )}
      <span className="button-label">
        {label}
      </span>
      {trailingIcon && (
        <span className="trailing-icon">
          {trailingIcon}
        </span>
      )}
    </button>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  leadingIcon: PropTypes.node,
  trailingIcon: PropTypes.node,
  color: PropTypes.string,
};

Button.defaultProps = {
  id: null,
  type: 'button',
  size: 'large',
  variant: 'primary',
  name: null,
  onClick: null,
  label: null,
  disabled: false,
  leadingIcon: null,
  trailingIcon: null,
  color: 'default',
};

export default Button;

import axios from 'axios';
import Bugsnag from '@bugsnag/js';

import { API_URL, API_TIMEOUT_MILLISECONDS, PATHS } from '~/lib/constants';
import history from '~/history';

const axiosClient = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT_MILLISECONDS,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
  (error) => {
    Bugsnag.notify(error);
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  response => response,
  (error) => {
    Bugsnag.notify(error);
    if (error?.response?.status === 401) {
      history.replace(PATHS.LOGOUT);
    }

    return Promise.reject(error);
  },
);

export function getRequest(URL: string) {
  return axiosClient.get(URL);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postRequest(URL: string, payload: any = {}) {
  return axiosClient.post(URL, payload);
}

export { axiosClient };

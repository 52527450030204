import PropTypes from 'prop-types';
import { CustomArrowProps } from 'react-slick';
import { ChevronLeft } from 'react-feather';

function PrevArrow({ className, onClick }: CustomArrowProps) {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label="Previous slides"
    >
      <ChevronLeft />
    </button>
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  onClick: null,
};

export default PrevArrow;

import PropTypes from 'prop-types';

export interface UiErrorMessageProps {
  error?: boolean;
  message?: string;
}

function ErrorMessage({ error, message }: UiErrorMessageProps) {
  if (!error || !message) {
    return null;
  }
  return (
    <p className="ui-error-message">
      {message}
    </p>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: false,
  message: null,
};

export default ErrorMessage;

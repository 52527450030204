import { Link } from 'react-router-dom';

import { PATHS } from '~/lib/constants';

function CreateAccountInvalid() {
  return (
    <>
      <h3 className="mb-2">Sign up for CreatorU</h3>
      <p className="pt-4">Sorry, an account has already been created with this registration link. Please check that your registration link is correct and try again.</p>
      <p className="pt-4"><Link to={PATHS.LOGIN}>Return to login</Link></p>
    </>
  );
}

export default CreateAccountInvalid;
